import WishlistButton from "common/buttons/WishlistButton/WishlistButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { localStorageData } from "services/auth/localStorageData";
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';


const UpComming = ({ upcoming }) => {
  const navigate = useNavigate();
  console.log("upcoming", upcoming)

  //calculating average rating and reviews
  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };


  return (
    <>
      <div className="md:flex flex-wrap gap-2">
        {upcoming.map((items, index) => {
          const favourite =
            items?.favouriteList &&
            items.favouriteList.find(
              (user) => user.userId === localStorageData("_id")
            );

          const averageRating = calculateAverageRating(items.property_id?.reviews || []);

          return (
            <div key={items._id || index} // Use a unique identifier
              className="md:flex gap-2 w-full md:w-[49%] my-4 border lg:h-[270px] xl:h-64 border-color-grey rounded-md overflow-hidden">
              <div className="w-full flex justify-between p-2">
                <div>
                  {averageRating > 0 ?
                    <div className="flex items-center">
                      <Rating
                        initialRating={averageRating}
                        readonly
                        emptySymbol={<FaRegStar color="#d3d3d3" />}
                        halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                        fullSymbol={<FaStar color="#ffd700" />}
                        fractions={2}
                      />
                      <span>({averageRating})</span>
                    </div>
                    :
                    <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                  }
                  <p className="text-xl font-semibold mt-2 min-h-16">
                    {items.property_id?.title?.length > 45
                      ? items.property_id?.title?.slice(0, 45) + "..."
                      : items.property_id?.title}
                  </p>
                  <p className="text-sm text-color-grey">
                    An entire place booked for you
                  </p>
                  <button className="border border-yellow-500 rounded-full px-5 py-2 mt-2  text-sm"
                    onClick={() =>
                      navigate(`/refund/${items._id}`)
                    }
                  >
                    Cancel Booking
                  </button>
                  <button
                    className="bg-blue-800 rounded-full px-4 mt-10 text-white block py-2"
                    onClick={() =>
                      navigate(`/propertydetails/${upcoming._id}`)
                    }
                  >
                    View Detail
                  </button>
                </div>
              </div>

              <div className="w-full md:w-96 lg:w-80 min-h-fit relative">
                <img
                  src={items?.property_id?.pics[0]}
                  alt=""
                  className="w-full h-full object-cover"
                />
                <span className="bg-color-yellow px-4 py-2 absolute top-2 text-sm text-white rounded-full right-2">
                  Pending
                </span>
              </div>
            </div>
          );
        })
        }
      </div>
    </>
  );
};

export default UpComming;
