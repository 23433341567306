import React, { useEffect } from 'react';
import icon1 from "assets/img/dropdwn.png"
import icon2 from "assets/img/Frame.png"
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import img1 from "assets/img/pm1.png"
import img2 from "assets/img/5stars.png"
import img3 from "assets/img/3group.png"
import img4 from "assets/img/pm 2.png"
import img5 from "assets/img/pm3.png"
import img6 from "assets/img/pm4.png"
import img7 from "assets/img/pm5.png"
import { IoIosArrowBack } from 'react-icons/io';
import TopCard from '../UserManagement/TopCard';
import { useState } from 'react';
import Pagination from './../../../components/Pagination/Pagination';
import StatusMenu from 'components/StatusMenu/StatusMenu';
import { useMutation } from "react-query";
import userServices from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import InputRange from 'react-input-range';
import SearchCard from 'components/Cards/SearchCard';
import Modal from "components/Modal/Modal";
import { RxCrossCircled } from 'react-icons/rx';

export default function ManageProperty({ setStepsAndDetail, allProperty, handleStatusChange }) {

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12);
  const [search, setSearch] = useState('');
  const [filteredProperties, setFilteredProperties] = useState(allProperty);
  const [propertyId, setPropertyId] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [filtered, setFiltered] = useState(allProperty);
  const [loading, setLoading] = useState(true);
  const [filterModal, setfilterModal] = useState(false);
  const [priceRange, setPriceRange] = useState({ min: 10, max: 3600 });
  const [bedrooms, setBedrooms] = useState(0);
  const [kitchens, setKitchens] = useState(0);
  const [bathrooms, setBathrooms] = useState(0);
  const [filtersApplied, setFiltersApplied] = useState(false);

  useEffect(() => {
    if (allProperty.length > 0) {
      setLoading(false);
    }
  }, [allProperty]);

  useEffect(() => {
    setFiltered(allProperty);
  }, [allProperty]);

  // Filter properties based on the search term entered.
  const filteredData = filtered.filter((item) =>
    item.address.toLowerCase().includes(search.toLowerCase())
  );

  // Function to reverse the order of the properties for adding the newly added properties at the top of the list
  const sortedData = (allProperty) => {
    return [...allProperty].reverse();
  };

  // Calculate total pages needed for pagination.
  const totalPages = Math.ceil(allProperty.length / itemsPerPage);
  const firstIndex = (currentPage - 1) * itemsPerPage;
  const lastIndex = currentPage * itemsPerPage;
  const currentPageData = sortedData(filtered).length > 0 && sortedData(filtered).slice(firstIndex, lastIndex);

  // const currentPageData = filteredData.slice(firstIndex, lastIndex);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleInputChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search

  };
  // const handleStatusChange = (itemId, newStatus) => {
  //   // Update the status locally
  //   const updatedProperties = allProperty.map(property =>
  //     property._id === itemId ? { ...property, status: newStatus } : property
  //   );
  //   // setStepsAndDetail(updatedProperties);
  // };


  // const [search, setSearch] = useState('');





  // console.log("allProperty@@@@", allProperty)

  // const [averageRating, setAverageRating] = useState(0);

  // useEffect(() => {
  //   if (allProperty?.reviews && allProperty.reviews.length > 0) {
  //     const ratings = allProperty.reviews.map((review) => review.rating);
  //     const total = ratings.reduce((acc, rating) => acc + rating, 0);
  //     const average = total / ratings.length;
  //     setAverageRating(Number(average.toFixed(1)));
  //   } else {
  //     // Handle the case where there are no reviews
  //     setAverageRating(0);
  //   }
  // }, [allProperty?.reviews]);


  // Function to select or deselect all users.
  const handleSelectAll = () => {
    const newIsAllSelected = !isAllSelected;
    setIsAllSelected(newIsAllSelected);

    if (newIsAllSelected) {
      // Select all users
      const allUserIds = filtered.map(user => user._id);
      setSelectedUsers(allUserIds);
      setFiltered(prevFiltered =>
        prevFiltered.map(user => ({ ...user, isSelected: true }))
      );
    } else {
      // Deselect all users
      setSelectedUsers([]);
      setFiltered(prevFiltered =>
        prevFiltered.map(user => ({ ...user, isSelected: false }))
      );
    }
  };

  // Handle the selection or deselection of individual users.
  const handleCheckboxChange = (userId) => {
    const updatedData = filtered.map(user => {
      if (user._id === userId) {
        return { ...user, isSelected: !user.isSelected };
      }
      return user;
    });

    const isSelected = selectedUsers.includes(userId);
    const updatedSelectedUsers = isSelected
      ? selectedUsers.filter(id => id !== userId)
      : [...selectedUsers, userId];

    setFiltered(updatedData);
    setSelectedUsers(updatedSelectedUsers);

    // Update the main checkbox state
    setIsAllSelected(updatedSelectedUsers.length === filtered.length);
  };

  useEffect(() => {
    // Ensure the main checkbox is selected if all users are selected
    setIsAllSelected(selectedUsers.length === filtered.length && filtered.length > 0);
  }, [selectedUsers, filtered]);

  const formatedDate = (timeStamp) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(timeStamp);
    return date.toLocaleDateString(undefined, options);
  };


  const [state, setState] = useState({
    value5: { min: 36, max: 3600 },
    bedrooms: 'Any',
    beds: 'Any',
    bathrooms: 'Any'
  });



  const incrementCounter = (setState) => {
    setState((prevCount) => prevCount + 1);
  };

  const decrementCounter = (setState) => {
    setState((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
  };

  const handleChange = (value) => {
    setPriceRange(value);
  };

  // Function to apply filters based on selected criteria.
  const handleFilter = () => {
    const filteredList = allProperty.filter((property) => {
      const withinPriceRange =
        property.points >= priceRange.min && property.points <= priceRange.max;
      const bedroomsMatch = bedrooms === 0 || property.spaceTypeDetail?.bedrooms >= bedrooms;
      const kitchensMatch = kitchens === 0 || property.spaceTypeDetail?.kitchen >= kitchens;
      const bathroomsMatch = bathrooms === 0 || property.spaceTypeDetail?.bathrooms >= bathrooms;

      // Get the selected status value
      const selectedStatus = document.querySelector('input[name="status"]:checked')?.value;

      const statusMatch = selectedStatus ? property.status === selectedStatus : true;

      return withinPriceRange && bedroomsMatch && kitchensMatch && bathroomsMatch && statusMatch;
    });

    setFiltered(filteredList);
    setFiltersApplied(true);
    setfilterModal(false); // Close the modal
    setCurrentPage(1); // Reset to first page on search
  };

  const resetFilter = () => {
    // Resetting filter values to their initial state
    setPriceRange({ min: 0, max: 3600 });
    setBedrooms(0);
    setKitchens(0);
    setBathrooms(0);

    // Unchecking all status radio buttons
    const statusRadios = document.querySelectorAll('input[name="status"]');
    statusRadios.forEach((radio) => {
      radio.checked = false;
    });

    // Resetting the filtered list to show all properties
    setFiltered(allProperty);
    setFiltersApplied(false);
    setfilterModal(false); 
    setCurrentPage(1); 
  };


  useEffect(() => {
    setFiltered(allProperty);
  }, [allProperty]);

  //exporting selected properties to the excel file
  const handleExportClick = () => {
    if (selectedUsers.length === 0) {
      toast.warning("Please select properties to export");
      return;
    }

    document.getElementById("test-table-xls-button").click();
  };

  return (
    <>
      <div className='md:p-6 p-2 bg-gray-100'>

        <div className='flex justify-between flex-wrap'>
          <div>
            <h1 className='sm:text-2xl text-xl font-extrabold'>Property Management</h1>
          </div>
        </div>

        <div className='pt-3'>
          <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
            <div className=''>
              <p className='font-medium sm:text-lg text-sm'>Properties</p>
            </div>
            <div className='flex  space-x-2'>
              <div onClick={() => setfilterModal(true)} className='flex justify-between sm:p-4 p-2 items-center cursor-pointer w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                <div>
                  <img src={icon3} />
                </div>
                <div>
                  <p className='font-normal text-xs text-gray-600'>Fillter</p>
                </div>
              </div>

              <div className='flex items-center justify-center'>
                {filtersApplied && (
                  <button onClick={resetFilter}>
                    <RxCrossCircled />
                  </button>
                )}
              </div>

              <button
                onClick={handleExportClick}
                className="flex justify-between sm:p-4 p-2 items-center w-full sm:w-[100px] h-[45px] border border-gray-300 rounded-md"
              >
                <div className='w-4 h-4'>
                  <img src={icon5} alt="Export Icon" className='w-full h-full' />
                </div>
                <div className='ml-2 sm:ml-0'>
                  <p className='font-normal text-xs text-gray-600'>Export</p>
                </div>
              </button>

              <ReactHtmlTableToExcel
                id="test-table-xls-button"
                className="hidden"
                table="selected-users-table"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export"
              />

              <div className="relative md:w-64  ">
                <input
                  type="text"
                  value={search}
                  onChange={handleInputChange}
                  placeholder='Search by location'
                  className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                />
                <img
                  src={icon4}
                  alt="search icon"
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                />
              </div>
            </div>
          </div>
        </div>


        <div className="overflow-x-auto">
          <table id="table-to-xls" className="table-auto border-collapse w-full bg-white rounded-xl">
            <thead>
              <tr className='bg-[#fafbff]'>
                <th className="px-4 py-2 border-t border-b text-left text-lg">
                  <input type="checkbox" onChange={handleSelectAll} checked={isAllSelected} className="mr-2" />
                  Properties
                </th>
                <th className="pr-4 pl-28 md:pl-7 lg:pl-4 border-t text-left border-b">Location</th>
                <th className="px-4 py-2 border-t text-left border-b">Room</th>
                <th className="px-4 py-2 border-t text-left border-b">Price</th>
                <th className="px-4 py-2 border-t text-left border-b">Host</th>
                <th className="px-4 py-2 border-t text-left border-b">Living Status</th>
              </tr>
            </thead>
            <tbody>

              {loading ? (
                <tr>
                  <td colSpan='6' className="text-center py-10">
                    <CircularProgress />
                  </td>
                </tr>

              )
                : (
                  currentPageData.length > 0 && currentPageData.map((data, index) => {
                    const ratings = data?.reviews?.length && data?.reviews?.map((review) => review.rating);
                    const total = ratings.length > 0 && ratings?.reduce((acc, rating) => acc + rating, 0);
                    const averageRating = ratings.length > 0 ? Number((total / ratings.length).toFixed(1)) : 0;
                    // console.log("data@@@@", data)
                    return (

                      <tr key={index}>
                        <td className="w-80 px-4 py-2 border-t border-b font-medium text-sm text-[#4B4B4B]">
                          <div className="flex items-center gap-3 ">
                            <input type="checkbox" checked={isAllSelected || data.isSelected} onChange={() => handleCheckboxChange(data._id)} className="mr-2" />

                            <div className=' w-24 h-20  flex-shrink-0'>
                              <img src={data.pics[0]} className="w-full h-full rounded-md object-cover" />
                            </div>
                            <div className='flex flex-col'>
                              <span onClick={() => setStepsAndDetail(data._id)} className="font-medium text-sm leading-5 text-black cursor-pointer">{data.title}</span>
                              {averageRating > 0 ?
                                <div className="flex md:flex-row flex-col">
                                  <Rating
                                    initialRating={averageRating}
                                    readonly
                                    emptySymbol={<FaRegStar color="#d3d3d3" />}
                                    halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                    fullSymbol={<FaStar color="#ffd700" />}
                                    fractions={2}
                                  />
                                  <span>({averageRating})</span>
                                </div>
                                :
                                <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                              }
                            </div>
                          </div>
                        </td>
                        <td className="pr-4 pl-28 md:pl-7 lg:pl-4 py-2 border-t border-b font-medium text-sm leading-5 text-[#4B4B4B]">
                          <span>{data.address}</span>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-[#4B4B4B]">
                          {data.spaceTypeDetail?.bathrooms} bed <br />
                          {data.spaceTypeDetail?.bedrooms} bath <br />
                          {data.spaceTypeDetail?.kitchen} kit
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          {data.points} Points
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                          {data.user.fname + " " + data.user.lname}
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black ">
                          <StatusMenu
                            initialStatus={data.status} // Assuming data.status contains the current status
                            itemId={data._id}
                            onStatusChange={handleStatusChange}
                            customProp="Custom Value"
                            statusTexts={['Pending', 'Active', 'Rejected']}
                          />
                          <br />
                          <div className='pt-2'>
                            <span className='text-[#4B4B4B]'>{formatedDate(data.timeStamp)}</span>
                          </div>
                        </td>
                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black"><img src={data.img3} alt="" /></td>
                      </tr>
                    )
                  }
                  ))}
            </tbody>

          </table>
          {/* Separate table for exporting selected users */}
          <table id="selected-users-table" style={{ display: 'none' }}>
            <thead>
              <tr>
                <th>Property title</th>
                <th>Location</th>
                <th>Rating</th>
                <th>Room</th>
                <th>Price</th>
                <th>Host</th>
                <th>Living Status</th>
                <th>Listing Date</th>
              </tr>
            </thead>
            <tbody>
              {sortedData(allProperty).filter(user => selectedUsers.includes(user._id)).map(property => {

                const ratings = property?.reviews?.length && property?.reviews?.map((review) => review.rating);
                const total = ratings.length > 0 && ratings?.reduce((acc, rating) => acc + rating, 0);
                const averageRating = ratings.length > 0 ? Number((total / ratings.length).toFixed(1)) : 0;

                return (
                  <tr key={property._id}>
                    <td>{property.title}</td>
                    <td>{property.address}</td>
                    <td>{averageRating}</td>
                    <td>bedrooms{" " + property.spaceTypeDetail.bathrooms},{" "} bathrooms{" " + property.spaceTypeDetail.bedrooms},{" "} kitchens{" " + property.spaceTypeDetail.kitchen}</td>
                    <td>{property.points} Points</td>
                    <td>{property.user.fname + " "}{property.user.lname}</td>
                    <td>{property.status}</td>
                    <td>{formatedDate(property.timeStamp)}</td>
                  </tr>
                )

              })}
            </tbody>
          </table>
        </div>

        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

      </div>
      <Modal
        isOpen={filterModal}
        onClose={() => setfilterModal(false)}
      >
        <div className="py-4 text-center font-bold">Filters</div>
        <hr className="text-[#C1E1C2]" />
        <div className="px-8 py-4 h-[340px] md:h-auto overflow-y-auto">

          <h2 className="font-bold text-center">Points range</h2>

          <div className="my-6">
            <InputRange
              draggableTrack
              maxValue={3600}
              minValue={10}
              onChange={handleChange}
              value={priceRange}
            />
          </div>
          <div className="flex gap-4 my-2">
            <div className="p-2 w-1/2 text-start border border-slate-400 rounded-md">
              <p className="text-xs">minimum</p>
              <p className="font-semibold"> {priceRange.min}</p>
            </div>
            <div className="p-2 w-1/2 text-start border border-slate-400 rounded-md">
              <p className="text-xs">maximum</p>
              <p className="font-semibold"> {priceRange.max}</p>
            </div>
          </div>


          <h2 className="font-bold text-center mt-5">Space Detail</h2>

          <div className="md:flex w-full gap-2 mt-2">
            <div className=" md:w-1/3 w-full ">
              <p className="text-center text-md mb-1">Bedrooms</p>
              <div className="flex items-center justify-center py-2 gap-1">
                <p
                  onClick={() => {
                    if (bedrooms > 0) {
                      setBedrooms(0); // Reset counter to 0 if counter is greater than 0
                    }
                  }}
                  className={`cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[100px] ${bedrooms === 0 ? 'bg-blue-500' : ''
                    }`}
                  style={{ color: bedrooms === 0 ? '#ffffff' : '' }} // Add inline style for text color
                >
                  Any
                </p>

                <p onClick={() => decrementCounter(setBedrooms)} className="cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                  -
                </p>
                <p className="p-1 text-xs text-center text-color-darkgrey/70 rounded-full w-[50px]">
                  {bedrooms}
                </p>
                <p onClick={() => incrementCounter(setBedrooms)} className="cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                  +
                </p>
              </div>

            </div>

            <div className=" md:w-1/3 w-full  ">
              <p className="text-center text-md mb-1">kitchens</p>
              <div className="flex items-center justify-center  py-2 gap-1">
                <p
                  onClick={() => {
                    if (kitchens > 0) {
                      setKitchens(0); // Reset counter to 0 if counter is greater than 0
                    }
                  }}
                  className={`cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[100px] ${kitchens === 0 ? 'bg-blue-500' : ''
                    }`}
                  style={{ color: kitchens === 0 ? '#ffffff' : '' }} // Add inline style for text color
                >
                  Any
                </p>

                <p onClick={() => decrementCounter(setKitchens)} className="cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                  -
                </p>
                <p className="p-1 text-xs text-center text-color-darkgrey/70 rounded-full w-[50px]">
                  {kitchens}
                </p>
                <p onClick={() => incrementCounter(setKitchens)} className="cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                  +
                </p>
              </div>
            </div>

            <div className=" md:w-1/3 w-full  ">
              <p className="text-center text-md mb-1">Bathrooms</p>
              <div className="flex items-center justify-center  py-2 gap-1">
                <p
                  onClick={() => {
                    if (bathrooms > 0) {
                      setBathrooms(0); // Reset counter to 0 if counter is greater than 0
                    }
                  }}
                  className={`cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[100px] ${bathrooms === 0 ? 'bg-blue-500' : ''
                    }`}
                  style={{ color: bathrooms === 0 ? '#ffffff' : '' }} // Add inline style for text color
                >
                  Any
                </p>

                <p onClick={() => decrementCounter(setBathrooms)} className="cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                  -
                </p>
                <p className="p-1 text-xs text-center text-color-darkgrey/70 rounded-full w-[50px]">
                  {bathrooms}
                </p>
                <p onClick={() => incrementCounter(setBathrooms)} className="cursor-pointer p-1 text-xs text-center text-color-darkgrey/70 border border-slate-300 rounded-full w-[50px]">
                  +
                </p>
              </div>
            </div>
          </div>

          <div className="mb-4 px-5 text-center flex flex-col gap-5">
            <p className="font-bold mt-5">Property Status</p>
            <div className="flex items-center justify-center space-x-2 md:space-x-4">
              <input type="radio" id="Active" name="status" value="Active" />
              <label htmlFor="guest">Active</label>
              <input type="radio" id="Pending" name="status" value="Pending" />
              <label htmlFor="host">Pending</label>
              <input type="radio" id="Rejected" name="status" value="Rejected" />
              <label htmlFor="host">Rejected</label>
            </div>
          </div>

          <div className='flex items-center justify-center mt-10'>
            <button
              className="bg-blue-600 py-2 px-3 text-white rounded-xl text-sm"
              onClick={handleFilter}
            >
              Filter
            </button>
          </div>
        </div>
      </Modal>



    </>
  )
}
