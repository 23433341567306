import React from "react";
import { ImageEndPoint } from "config/config";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Divider, Tooltip } from "@mui/material";
import { localStorageData } from "services/auth/localStorageData";
import userServices from "services/httpService/userAuth/userServices";
import WishlistButton from "common/buttons/WishlistButton/WishlistButton";
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

export default function HomeCard({
  data,
  favourite,
  favoriteRender,
  setFavoriteRender,
}) {
  let navigate = useNavigate();
  const [propertyId, setPropertyId] = useState("");
  const [userId, setUserId] = useState("");
  const coverImge = data.pics[0];
  const [image, setImage] = useState([]);

  // getting user data
  const getUserData = async () => {
    let res = await userServices.userById(
      `/userAuth/user/${(localStorageData("_id"))}`
    );
    console.log("res.data.data", res?.data?._id);
    setUserId(res?.data?._id); // Set userId here
  };

  //update image array and fetch user data on component mount
  useEffect(() => {
    let allimages = data.pics.map((item) => {
      return ImageEndPoint + item;
    });
    setImage(allimages);
    getUserData();
  }, []);

  const [averageRating, setAverageRating] = useState(0);

  //calculating average reviews and ratings
  useEffect(() => {
    if (data?.reviews && data.reviews.length > 0) {
      const ratings = data.reviews.map((review) => review.rating);
      const total = ratings.reduce((acc, rating) => acc + rating, 0);
      const average = total / ratings.length;
      setAverageRating(Number(average.toFixed(1)));
    } else {
      setAverageRating(0);
    }
  }, [data?.reviews]);

  return (
    <>
      <div className="flex flex-wrap justify-between gap-6 pt-6">
        <div className="md:w-[265px] w-full">
          <Tooltip title={userId ? "" : "Please login to view details"} arrow>
            <div
              className={`cursor-pointer md:w-[265px] rounded-t-xl w-full h-[200px] bg-center bg-cover ${userId ? '' : 'pointer-events-none'}`}
              style={{
                backgroundImage: `url(${coverImge})`,
              }}
              onClick={() => userId && navigate(`/propertydetails/${data._id}`)}
            ></div>
          </Tooltip>
          <div className="md:w-[265px] w-full py-3 h-auto rounded-b-xl bg-white border flex flex-col justify-between">
            <div className="px-4">
              <div className="flex justify-between">
                {averageRating > 0 ?
                  <div className="flex items-center gap-2">
                    <Rating
                      initialRating={averageRating}
                      readonly
                      emptySymbol={<FaRegStar color="#d3d3d3" />}
                      halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                      fullSymbol={<FaStar color="#ffd700" />}
                      fractions={2}
                    />
                    <span>({averageRating})</span>
                  </div>
                  :
                  <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                }
                <div className="">
                  <WishlistButton
                    propertyId={data._id}
                    favourite={favourite}
                    favoriteRender={favoriteRender}
                    setFavoriteRender={setFavoriteRender}
                  />
                </div>
              </div>
              <div style={{ height: "3.3rem" }} className="mb-2"> {/* 2 lines height */}
                <Tooltip title={userId ? "" : "Please login to view details"} arrow>
                  <h4
                    className="text-[#000000] text-lg font-bold cursor-pointer hover:text-blue-500 overflow-hidden"
                    style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
                    onClick={() => userId && navigate(`/propertydetails/${data._id}`)}
                  >
                    {data.title}
                  </h4>
                </Tooltip>
              </div>
              <div>
                <h4 className="text-[#000000] text-base font-medium">
                  {data.address.length > 25 ? data.address.substring(0, 25) + '...' : data.address}
                </h4>
              </div>
              <div className="text-[#AFB5C1]">
                <span className="text-base font-normal">
                  {data.spaceTypeDetail &&
                    data.spaceTypeDetail.bathrooms &&
                    data.spaceTypeDetail.bedrooms &&
                    data.spaceTypeDetail.guests &&
                    data.spaceTypeDetail.kitchen && (
                      <>
                        Guests:{" "}
                        {data.spaceTypeDetail.guests &&
                          data.spaceTypeDetail.guests}{" | "}
                        Bedrooms:{" "}
                        {data.spaceTypeDetail.bedrooms &&
                          data.spaceTypeDetail.bedrooms}{" "}
                        Kitchens:{" "}
                        {data.spaceTypeDetail.kitchen &&
                          data.spaceTypeDetail.kitchen}{" | "}
                        Bathrooms:{" "}
                        {data.spaceTypeDetail.bathrooms &&
                          data.spaceTypeDetail.bathrooms}{" "}
                      </>
                    )}
                </span>
              </div>
            </div>
            <div className="pt-2">
              <Divider />
            </div>
            <div className="px-4 pb-2">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-[#AFB5C1] text-base font-normal">From</p>
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-[#AFB5C1] text-sm line-through">
                    {data.points && data.points}
                  </span>
                  <span className="text-[#C0392D] text-base">
                    {data.points && data.points} Points
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
