import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import GoogleMapReact from "google-map-react";
import ListingColor from "components/Cards/ListingColor";
import AutoAddress from "components/AutoAddress";

// Component to display the map marker
const AnyReactComponent = ({ text }) => (
  <div>
    <img
      src="https://i.ibb.co/xqDW27s/Vector.png"
      alt="Vector"
      className="object-contain w-10 h-10"
    />
  </div>
);
export default function PropertyAddress({ state, setState }) {
  // Default properties for the map: center position and zoom level
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  // Function to update the state with the new address, latitude, and longitude
  const changeAddress = (address, lat, long) => {
    setState((prevState) => ({ ...prevState, address: address }));
    setState((prevState) => ({ ...prevState, lat: lat }));
    setState((prevState) => ({ ...prevState, long: long }));
  };

  return (
    <div>
      <Grid container>
        <Grid item lg={6} md={6} sm={12} xs={12} style={{ height: "85vh", marginTop: '10vh' }}>
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Where Is Your Rental Property Located?"
            color="text-[#FFFFFF]"
            btn="step 3"
          />
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}
          style={{ marginTop: '10vh', height: "85vh", paddingBottom: "50px", overflowY: "auto", overflowX: 'hidden' }}
        >
          <div
            style={{
              position: "relative",
              height: "80vh",
              width: "100%",

            }}
          >
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyC23GAPasXhA6vYd7VYALzaPcFJufEUb5s",
              }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              style={{ width: "100%", height: "100%" }}
            >
              <AnyReactComponent
                lat={10.99835602}
                lng={77.01502627}
                text="My Marker"
              />
            </GoogleMapReact>

            <div
              className="absolute top-12 left-1/2 transform w-[80%] -translate-x-1/2 -translate-y-1/2 z-10"
              style={{
                zIndex: 10,
              }}
            >
              <div className=" rounded-md flex bg-white">
                <img
                  src={require("assets/img/location.png")}
                  alt=""
                  className="w-12 p-2 h-12"
                />
                <div className="w-full">
                  <AutoAddress
                    className="p-3 text-md  rounded-lg w-full outline-none  "
                    placeholder="Enter your Address"
                    changeaddress={changeAddress}
                    address={state.address}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
