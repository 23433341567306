// module.exports = {
//   endPoint: 'http://localhost:5000',
//   ImageEndPoint: "http://localhost:5000/readfiles/",
//   baseUrl:'https://api.bnbyond.com',
//   localToken: "Bnbyond",
//   // mapkey: "AIzaSyBu2WqDbYFglNC_u5HHcoFQmCgnxps6vH8",
//   mapkey: "AIzaSyC23GAPasXhA6vYd7VYALzaPcFJufEUb5s",
//   googleapi:
//     "1083164283457-ba4ifmo5qddc45b26mnlv34967a2pe9n.apps.googleusercontent.com",
//   // facebookapi: '816091399416373',
//   facebookapi: "470367605085716",
//   SECRET_ENCRYPTION_KEY:"asdfghjklqwertyuiopzxcvbnm123456",
//   Standard_ClientId: "ca_QGwlEK3pPfrSI6QcMrzbxSJe8XlmgkEC",
//  textEditorTinyKey:"muhvdqcz9lqg5jgayj93eeyismpcu19zu9u38c6rnuz7l98n",
//  stripe_publishkey:'pk_test_51PMPtcEqiwonxz3FrzRWHGQs9oicqwzTQZGOyVjoxblTseNplqUSubZ93vYI1QXwn4Zu3MqNqUl2GmHnv9KTvJcy00aom7bhyS'
// };

// production links 
module.exports = {

  endPoint: 'https://api.bnbyond.com/api',
  baseUrl:'https://api.bnbyond.com',
  ImageEndPoint: 'https://api.bnbyond.com/readfiles/',
  localToken: 'Bnbyond',
  // mapkey: 'AIzaSyBu2WqDbYFglNC_u5HHcoFQmCgnxps6vH8',
  mapkey: 'AIzaSyC23GAPasXhA6vYd7VYALzaPcFJufEUb5s',
  googleapi:
    '1083164283457-ba4ifmo5qddc45b26mnlv34967a2pe9n.apps.googleusercontent.com',
  // facebookapi: '816091399416373',
  facebookapi: '470367605085716',
   SECRET_ENCRYPTION_KEY:"asdfghjklqwertyuiopzxcvbnm123456",
  Standard_ClientId: "ca_QGwlEK3pPfrSI6QcMrzbxSJe8XlmgkEC",
  textEditorTinyKey:"muhvdqcz9lqg5jgayj93eeyismpcu19zu9u38c6rnuz7l98n",
stripe_publishkey:'pk_test_51PMPtcEqiwonxz3FrzRWHGQs9oicqwzTQZGOyVjoxblTseNplqUSubZ93vYI1QXwn4Zu3MqNqUl2GmHnv9KTvJcy00aom7bhyS'
};

// staging links 

// module.exports = {

//   endPoint: 'http://18.206.183.48/api',
//   baseUrl:'http://18.206.183.48/api',
//   ImageEndPoint: 'http://18.206.183.48/api/readfiles/',
//   localToken: 'Bnbyond',
//   // mapkey: 'AIzaSyBu2WqDbYFglNC_u5HHcoFQmCgnxps6vH8',
//   mapkey: 'AIzaSyC23GAPasXhA6vYd7VYALzaPcFJufEUb5s',
//   googleapi:
//     '1083164283457-ba4ifmo5qddc45b26mnlv34967a2pe9n.apps.googleusercontent.com',
//   // facebookapi: '816091399416373',
//   facebookapi: '470367605085716',
//    SECRET_ENCRYPTION_KEY:"asdfghjklqwertyuiopzxcvbnm123456",
//   Standard_ClientId: "ca_QGwlEK3pPfrSI6QcMrzbxSJe8XlmgkEC",
//   textEditorTinyKey:"muhvdqcz9lqg5jgayj93eeyismpcu19zu9u38c6rnuz7l98n",
// stripe_publishkey:'pk_test_51PMPtcEqiwonxz3FrzRWHGQs9oicqwzTQZGOyVjoxblTseNplqUSubZ93vYI1QXwn4Zu3MqNqUl2GmHnv9KTvJcy00aom7bhyS'
// };

