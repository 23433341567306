import React, { useEffect, useState } from "react";
import userServices from "services/httpService/userAuth/userServices";
import icon3 from "assets/img/filter.png";
import icon4 from "assets/img/searchbar.png";
import icon5 from "assets/img/export.png";
import Pagination from "components/Pagination/Pagination";
import StatusMenu from "components/StatusMenu/StatusMenu";
import { CircularProgress } from "@mui/material";
import Rating from "react-rating";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { localStorageData } from "services/auth/localStorageData";
import PopupModal from "components/PopupModal/PopupModal";
import ErrorService from "services/formatError/ErrorService";
import { toast } from "react-toastify";
import Modal from "components/Modal/Modal";


const RefundReservation = () => {
  const [reservationData, setReservationData] = useState([]);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [serviceFee, setServiceFee] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [openAlert, setOpenAlert] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setbtnLoader] = useState(false);

  const [selectedReservationId, setSelectedReservationId] = useState("");
  const [actionType, setActionType] = useState("");
  const [refundMessage, setRefundMessage] = useState("");

  // Function to fetch reservations with a refund request.
  const getReservations = async () => {
    try {
      const res = await userServices.commonGetService(
        `/reservation/reservations/${localStorageData("_id")}?role=admin`
      );
      if (res.data && Array.isArray(res.data)) {
        // Filter reservations that have a refund request.
        const filteredReservations = res.data.filter(
          (reservation) => reservation.isRefundRequest === true
        );
        setReservationData(filteredReservations);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching reservation data:", error);
      setLoading(false);
    }
  };

  // Function to handle refund approval or rejection.
  const refundApproval = async () => {
    try {
      setbtnLoader(true)

      const refundStripePayment = `/stripe/refundpayment`;
      await userServices.stripeRefund(refundStripePayment, {
        paymentIntentId: paymentIntentId,
        amount: serviceFee,
      }); // Process the refund via Stripe.

      const refundPoints = `/reservation/refundApproval/${selectedReservationId}`;
      await userServices.refundReservation(refundPoints, {
        status: actionType,
      });// Update the reservation status based on approval or rejection.

      toast.success(
        `Reservation Request Successfully ${actionType === "approved" ? "Refunded" : "Rejected"
        }`
      );
      setLoader(true);

    } catch (error) {
      toast.error(ErrorService.uniformError(error?.response?.data?.error));
    }
    setbtnLoader(false)

  };

  useEffect(() => {
    getReservations();
  }, [loader]);

  //logic to reverse the data aray so that newly refunded reservation appear at the top of the list
  const sortedData = (data) => {
    return [...data].reverse();
  };

  //page calculation for pagination
  const totalPages = Math.ceil(reservationData.length / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData(reservationData).length > 0 && sortedData(reservationData).slice(indexOfFirstItem, indexOfLastItem);
  // const currentItems = reservationData.slice(indexOfFirstItem, indexOfLastItem);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to handle initiating a refund or rejection.
  const handleRefund = (reservationId, action, paymentIntentId, serviceFee) => {
    setSelectedReservationId(reservationId);
    setActionType(action);
    setOpenAlert(true);
    setPaymentIntentId(paymentIntentId);
    setServiceFee(Math.floor(serviceFee * 0.8));
  };

  const handleCancelModal = () => {
    setOpenAlert(false);
  };

  const handleAcceptModal = () => {
    setOpenAlert(false);
    refundApproval();
  };

  if (loading) {
    return <CircularProgress />;
  }

  const handleClose = () => {
    setIsOpen(false);
  }
  const handleView = (message) => {
    // console.log("message", message)
    setRefundMessage(message)
    setIsOpen(true)
  }

  // Function to calculate the average rating from a list of reviews.
  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };

  return (
    <>
      {openAlert && (
        <PopupModal
          handleCancle={handleCancelModal}
          handleAccept={handleAcceptModal}
          openAlert={openAlert}
          setOpenAlert={setOpenAlert}
          description={`Are you sure you want to ${actionType} the refund request? This action cannot be undone.`}
          title={`Confirm ${actionType === "approved" ? "Refund" : "Rejection"
            }`}
          acceptbtnText="Confirm"
        />
      )}

      <Modal isOpen={isOpen} onClose={handleClose}>
        <h1 className="text-2xl font-bold text-center p-6">Refund Reason</h1>
        <hr className="font-bold" />
        <div className="flex jus items-center h-[80%] w-full">
          <p className="text-center w-full px-10 text-xl italic">{refundMessage}</p>
        </div>
      </Modal>

      <div className="overflow-x-auto">
        <table
          id="table-to-xls"
          className="table-auto border-collapse w-full bg-white rounded-xl"
        >
          <thead>
            <tr className="bg-[#fafbff] w-full">
              <th className="px-4 py-2 text-left text-lg">Refunded Reservations</th>
              <th className="pr-4 pl-28 md:pl-7 lg:pl-4 border-t text-left border-b">
                Location
              </th>
              <th className="px-4 py-2 border-t text-left border-b">Points</th>

              <th className="px-4 py-2 border-t text-left border-b">Message</th>
              <th className="px-4 py-2 border-t text-left border-b">
                Refund Status
              </th>

              <th className="px-4 py-2 border-t text-left border-b">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((data, index) => {
              const averageRating = calculateAverageRating(data.property_id?.reviews || []);

              const isActionDisabled =
                data?.isRefundStatus === "refunded" ||
                data?.isRefundStatus === "rejected";
              return (
                <tr key={index} className="border-t border-b">
                  <td className="w-80 px-4 py-2 font-medium text-sm text-[#4B4B4B]">
                    <div className="flex items-center gap-3">
                      <div className="w-24 h-20 flex-shrink-0">
                        <img
                          src={data?.property_id?.pics[0]}
                          className="w-full h-full rounded-md object-cover"
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-medium text-sm leading-5 text-black cursor-pointer">
                          {data?.property_id?.title}
                        </span>
                        {averageRating > 0 ? (
                          <div className="flex md:flex-row flex-col">
                            <Rating
                              initialRating={averageRating}
                              readonly
                              emptySymbol={<FaRegStar color="#d3d3d3" />}
                              halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                              fullSymbol={<FaStar color="#ffd700" />}
                              fractions={2}
                            />
                            <span>({averageRating})</span>
                          </div>
                        ) : (
                          <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="pr-4 pl-28 md:pl-7 lg:pl-4 py-2 font-medium text-sm leading-5 text-[#4B4B4B]">
                    <span>{data?.property_id?.address}</span>
                  </td>

                  <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                    {data?.offerState?.points} Points
                  </td>
                  <td className="px-4 py-2 font-medium text-sm leading-5 text-blue-700">
                    <button onClick={() => handleView(data?.refundReason)}>View Message</button>
                  </td>
                  <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                    {data?.isRefundStatus}
                  </td>
                  <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                    <button
                      className={`px-4 py-2 m-1 rounded-md ${isActionDisabled || btnLoader
                        ? "bg-gray-400"
                        : "bg-[#FFA500] text-white"
                        } `}
                      onClick={() =>
                        handleRefund(
                          data._id,
                          "approved",
                          data.paymentIntentId,
                          data.offerState.serviceFee
                        )
                      }
                      disabled={isActionDisabled || btnLoader}
                    >
                      Refund
                    </button>
                    <button
                      className={`px-4 py-2 m-1 rounded-md ${isActionDisabled || btnLoader
                        ? "bg-gray-400"
                        : "bg-[#FF0000] text-white"
                        }`}
                      onClick={() =>
                        handleRefund(data._id, "rejected", data.paymentIntentId, data.offerState.serviceFee)
                      }
                      disabled={isActionDisabled || btnLoader}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {reservationData.length > itemsPerPage && (
          <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
        )}
      </div>
    </>
  );
};

export default RefundReservation;
