import React, { useState, } from "react";
import { useFormik } from "formik";
import { useLocation } from 'react-router-dom';
import { useNavigate, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import * as Yup from "yup";
import { useCreateEmailAccount } from "hooks";
import FacebookApi from "components/SocialLogin/FacebookApi";
import GoogleApi from "components/SocialLogin/GoogleApi";
import Modal from "components/Modal/Modal";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { countries } from "countries-list";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


function InfluencerSignup() {
  const location = useLocation();
  // Extract query parameters from the URL
  const queryParams = new URLSearchParams(location.search);
  const referralCode = queryParams.get('referralCode')

  const [isModalOpen, setIsModalOpen] = useState(false);
  let navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [isChecked, setChecked] = useState(false);

  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  // Function to toggle the checkbox state when a label is clicked
  const handleLabelClick = () => {
    setChecked(!isChecked);
  };

  // Hook to handle the API call for creating an email account
  const { mutateAsync: createEmailAccount, isLoading } =
    useCreateEmailAccount();

  const openModal = () => {
    setIsModalOpen(true);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Formik function for managing form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      phoneNumber: "",
      country: "",
      town: "",
      pass: "",
      referralCode: referralCode || '',
      isReferralCode: referralCode ? true : false,
      roles: ["Guest", "Host", "Influencer"]
    },
    onSubmit: async (values, { resetForm }) => {
      if (!isChecked) {
        console.error("Please agree to the terms and conditions.");
        return;
      }
      const { email } = values;
      // Extract username from email (text before @)
      const username = email.split("@")[0];
      // Update form values with the extracted username
      const response = await createEmailAccount({ ...values, username, });
      // setIsModalOpen(true);
      navigate("/auth/signin");
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().required("First Name is required"),
      lname: Yup.string().required("Last Name is required"),
      email: Yup.string().email("Invalid email address").required("Email is required"),
      phoneNumber: Yup.string().required("Phone Number is required"),
      country: Yup.string().required("Country is required"),
      town: Yup.string().required("Town/City is required"),
      pass: Yup.string().min(8, "Password must be at least 8 characters").required("Password is required"),
    }),
  });
  return (
    <>
      <div className=" flex rounded-lg  shadow sm:w-[80%] md:h-[840px]  mx-auto overflow-hidden ">
        <div className="w-full h-full rounded-md ">
          <div className="relative flex flex-col w-full min-w-0 break-words border-0 rounded-lg bg-auth">
            <div className="px-6 py-6 mb-0 rounded-t">
              <div className="mb-3 text-center">
                <h6 className="text-2xl font-bold text-grey-500">Influencer Registration </h6>
                <p className="text-grey-500 text-md ">
                  Welcome! Let’s get started!
                </p>
              </div>

            </div>
            <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
              <form onSubmit={formik.handleSubmit}>
                <div className="items-center gap-2 sm:flex">
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-base font-medium text-[#000000]"
                      htmlFor="grid-name"
                    >
                      First Name
                    </label>
                    <input
                      id="fname"
                      name="fname"
                      type="text"
                      className="w-full px-3 py-3 font-normal placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                      placeholder="First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.fname}
                    />

                    <div className="md:h-7 lg:h-4"> {/* Adjust this height as needed */}
                      {/* Actual error message */}
                      {formik.touched.fname && formik.errors.fname ? (
                        <div className="py-1 text-sm text-red-500">
                          {formik.errors.fname}
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-base font-medium text-[#000000]"
                      htmlFor="grid-name"
                    >
                      Last Name
                    </label>
                    <input
                      id="lname"
                      name="lname"
                      type="text"
                      className="w-full px-3 py-3 font-normal placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                      placeholder="Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lname}
                    />
                    {/* Error message container with fixed height */}
                    <div className="md:h-7 lg:h-4"> {/* Adjust this height as needed */}
                      {/* Actual error message */}
                      {formik.touched.lname && formik.errors.lname ? (
                        <div className="py-1 text-sm text-red-500">
                          {formik.errors.lname}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="items-center gap-2 lg:flex">
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-base font-medium text-[#000000]"
                      htmlFor="grid-email"
                    >
                      Email Address
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="w-full px-3 py-3 font-normal placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                      placeholder="Enter Email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />

                    <div className="lg:h-5">
                      {formik.touched.email && formik.errors.email ? (
                        <div className="py-1 text-sm text-red-500">
                          {formik.errors.email}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block mb-2 text-base font-medium text-[#000000]"
                      htmlFor="grid-email"
                    >
                      Phone No
                    </label>

                    <PhoneInput
                      country={"us"} // Set the default country (optional)
                      value={formik.values.phoneNumber} // Set the phone number value
                      onChange={(phoneNumber) => formik.setFieldValue("phoneNumber", phoneNumber)} // Handle phone number changes
                      onBlur={formik.handleBlur} // Handle onBlur event
                      inputStyle={{
                        width: "100%", // Set input width
                        height: "3rem", // Set input height
                        fontSize: "1rem", // Set font size
                        border: "1px solid #ced4da", // Set border style
                        borderRadius: "0.375rem", // Set border radius
                        paddingLeft: "3rem", // Set left padding
                        boxSizing: "border-box", // Set box sizing
                        outline: "none", // Remove outline
                      }}
                    />
                    <div className="lg:h-5">
                      {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="py-1 text-sm text-red-500">
                          {formik.errors.phoneNumber}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-base font-medium text-[#000000]"
                    htmlFor="country"
                  >
                    Country
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="w-full px-3 py-3 font-normal placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.country}
                  >
                    <option value="" label="Select a country" />
                    {/* Mapping over the countries object to create options */}
                    {Object.keys(countries).map(countryCode => (
                      <option key={countryCode} value={countryCode} label={countries[countryCode].name} />
                    ))}
                  </select>
                  {formik.touched.country && formik.errors.country ? (
                    <div className="py-1 text-sm text-red-500">
                      {formik.errors.country}
                    </div>
                  ) : null}
                </div>

                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-base font-medium text-[#000000]"
                    htmlFor="grid-password"
                  >
                    Town / City
                  </label>
                  <input
                    name="town"
                    id="town"
                    type="text"
                    className="w-full px-3 py-3 font-normal placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                    placeholder="Enter Town/City"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.town}
                  />
                  {formik.touched.town && formik.errors.town ? (
                    <div className="py-1 text-sm text-red-500">
                      {formik.errors.town}
                    </div>
                  ) : null}
                </div>
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-base font-medium text-[#000000]"
                    htmlFor="grid-password"
                  >
                    Password
                  </label>

                  <div className="relative">
                    <input
                      name="pass"
                      id="pass"
                      type={showPassword ? "text" : "password"}
                      className="w-full px-3 py-3 font-normal placeholder:text-sm text-[#929293] transition-all duration-150 ease-linear bg-white border border-gray-300 rounded-xl  focus:outline-none focus:ring"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.pass}
                    />
                    <div
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <IoEyeOutline className="text-[#929293] text-xl" />
                      ) : (
                        <IoEyeOffOutline className="text-[#929293] text-xl" />
                      )}
                    </div>
                  </div>

                  {formik.touched.pass && formik.errors.pass ? (
                    <div className="py-1 text-sm text-red-500">
                      {formik.errors.pass}
                    </div>
                  ) : null}
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className=""
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <span
                    className="ml-2 text-sm"
                    onClick={handleLabelClick}
                    style={{ cursor: "pointer" }}
                  >
                    I agree on all conditions and terms.
                  </span>
                </div>
                {!isChecked && formik.submitCount > 0 && (
                  <small className="text-red-500 ">
                    Please agree to the terms and conditions.
                  </small>
                )}
                <div className="mt-6 text-center">
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <button
                        //  className="btn-styl"
                        className="w-full px-6 py-3 mb-1 mr-1 font-bold text-white bg-blue-500 rounded-full shadow text-md hover:shadow-lg "
                        type="submit"
                      >
                        Create Account
                      </button>
                    </>
                  )}
                </div>

                {/* <GoogleApi name="Continue with Google" /> */}
              </form>
              <p className="flex items-center justify-center mt-4 text ">
                <span className="text-gray-400">Already have an account? </span>
                <a href="/auth/signin" className="ml-2 font-semibold">
                  Sign In
                </a>
              </p>
            </div>
          </div>
        </div>

        <div className="relative hidden w-full h-full md:block">
          <div className="absolute top-0 left-0 flex flex-col justify-end w-full h-full px-8 py-10 rounded-lg bg-black/50">
            <h2 className="text-3xl font-semibold text-white">
              Join the Journey - Sign Up
            </h2>
            <h2 className="text-3xl font-semibold text-white">
              for Your bnbyond Account
            </h2>
            <div className="flex items-center mt-4">
              <img
                src={require("assets/img/icon.png")}
                className="w-4 mr-2 "
                alt=""
              />
              <p className="text-white">
                Embark on a new adventure with bnbyond!
              </p>
            </div>
          </div>
          <div className="h-full ">
            <img
              className="object-cover logo-landing "
              src={require("assets/img/signup.png")}
            />
          </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <img
            src={require("../../../assets/img/verify.png")}
            alt=""
            className="block mx-auto w-[180px]"
          ></img>
          <h2 className="mb-2 text-2xl font-bold text-center">
            Check Your Email
          </h2>
          <p className="text-center text-gray-700">
            If we find an account matching this information, you will
          </p>
          <p className="text-center text-gray-700">
            Receive a password reset link to your email
          </p>
        </Modal>
      </div>
    </>
  );
}

export default InfluencerSignup;
