import React, { useEffect, useState } from "react";
import { localStorageData } from "services/auth/localStorageData";
import Rating from "react-rating";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";

const ReservationCrad = ({ pastTrips, handleWishlist }) => {
  const [showMore, setShowMore] = useState(false);
  const defaultNumberOfCards = 2; // Number of cards to show by default
  const [averageRating, setAverageRating] = useState(0);

  // Toggle show more cards
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  console.log("pastTrips@@", pastTrips)

  //calculating average reviews and ratings
  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };



  return (
    <div className="space-y-3">
      <p className="text-2xl font-medium">Your past trips</p>
      {pastTrips.length > 0 ? (
        <>

          {pastTrips.slice(0, defaultNumberOfCards).map((item, index) => {
            const favourite =
              item?.property_id?.favouriteList &&
              item.property_id?.favouriteList.find(
                (user) => user.userId === localStorageData("_id")
              );

            // Calculate average rating for this trip
            const averageRating = calculateAverageRating(item.property_id?.reviews || []);
            console.log("item@@@@@@@@@@@@", item)
            return (
              <div
                key={item._id || index} // Use a unique identifier
                className="flex flex-col justify-between w-full md:flex-row"
              >
                {/* Card content */}
                <div className="w-full md:w-[60%] flex flex-col justify-between border order-last md:order-first border-color-green md:border-r-0 md:rounded-tl-lg md:rounded-bl-lg md:rounded-tr-none p-4">
                  <div className="">
                    <div className="flex items-center justify-between ">
                      {averageRating > 0 ?
                        <div className="flex items-center justify-start text-sm lg:text-lg xl:text-xl">
                          <Rating
                            initialRating={averageRating}
                            readonly
                            emptySymbol={<FaRegStar color="#d3d3d3" />}
                            halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                            fullSymbol={<FaStar color="#ffd700" />}
                            fractions={2}
                          />
                          <span>({averageRating})</span>
                        </div>
                        :
                        <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                      }
                      <div className="">
                        <button
                          className={`fa-regular ${favourite && "text-color-red fa-solid"
                            } fa-heart w-[17.14px] h-[15.7px]`}
                          onClick={() => handleWishlist(item._id)}
                        ></button>
                      </div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <h1 className=" text-lg md:text-sm xl:text-xl">{item.property_id?.title}</h1>
                      <p className="text-lg font-medium ">
                        {item.property_id?.address}
                      </p>
                    </div>
                  </div>

                  <div className="">
                    <hr className="mt-4 mb-2 border-t border-[#AFB5C1]" />
                    <div className="flex justify-between">
                      <p className="text-[#AFB5C1]">From</p>
                      <div className="flex gap-4">
                        <p className="text-[#AFB5C1] !line-through italic">
                          {item.property_id?.points}
                        </p>
                        <p className="text-[#C0392D] font-medium">
                          {" "}
                          {item.property_id?.points} Points
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full h-40 md:h-[300px] lg:h-60 md:w-[40%] overflow-hidden rounded-lg rounded-br-none rounded-bl-none md:rounded-br-lg md:rounded-tl-none md:rounded-bl-none md:rounded-tr-lg  order-first md:order-last">
                  <img
                    src={item.property_id?.pics[0]}
                    alt=""
                    className="object-cover w-full h-full flex-shrink-0"
                  />
                </div>
              </div>
            );
          })}

          {/* Show More button */}
          {!showMore && pastTrips.length > defaultNumberOfCards && (
            <button
              className="text-blue-500 underline"
              onClick={toggleShowMore}
            >
              Show More
            </button>
          )}

          {/* Render additional cards if showMore is true */}
          {showMore &&
            pastTrips.slice(defaultNumberOfCards).map((item, index) => {

              // Calculate average rating for this trip
              const averageRating = calculateAverageRating(item.property_id?.reviews || []);

              // Check if user is logged in and if the property is favorited by the user
              const favourite =
                localStorageData("_id") &&
                item?.property_id?.favouriteList &&
                item.property_id?.favouriteList.find(
                  (user) => user.userId === localStorageData("_id")
                );

              return (
                <div
                  key={item._id || index} // Use a unique identifier
                  className="flex flex-col justify-between w-full md:flex-row"
                >
                  {/* Card content */}
                  <div className="w-full md:w-[60%] flex flex-col justify-between border order-last md:order-first border-color-green md:border-r-0 md:rounded-tl-lg md:rounded-bl-lg md:rounded-tr-none p-4">
                    <div className="">
                      <div className="flex items-center justify-between ">
                        {averageRating > 0 ?
                          <div className="flex items-center justify-start text-sm lg:text-lg xl:text-xl ">
                            <Rating
                              initialRating={averageRating}
                              readonly
                              emptySymbol={<FaRegStar color="#d3d3d3" />}
                              halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                              fullSymbol={<FaStar color="#ffd700" />}
                              fractions={2}
                            />
                            <span>({averageRating})</span>
                          </div>
                          :
                          <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                        }
                        <div className="">
                          <button
                            className={`fa-regular ${favourite && "text-color-red fa-solid"
                              } fa-heart w-[17.14px] h-[15.7px]`}
                            onClick={() => handleWishlist(item._id)}
                          ></button>
                        </div>
                      </div>
                      <div className="flex flex-col gap-3">
                        <h1 className=" text-lg md:text-sm xl:text-xl">{item.property_id?.title}</h1>
                        <p className="text-lg font-medium ">
                          {item.property_id?.address}
                        </p>
                      </div>
                    </div>

                    <div className="">
                      <hr className="mt-4 mb-2 border-t border-[#AFB5C1]" />
                      <div className="flex justify-between">
                        <p className="text-[#AFB5C1]">From</p>
                        <div className="flex gap-4">
                          <p className="text-[#AFB5C1] !line-through italic">
                            {item.property_id?.points}
                          </p>
                          <p className="text-[#C0392D] font-medium">
                            {" "}
                            {item.property_id?.points} Points
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-40 md:h-[300px] lg:h-60 md:w-[40%] overflow-hidden rounded-lg md:rounded-tl-none md:rounded-bl-none md:rounded-tr-lg  order-first md:order-last">
                    <img
                      src={item.property_id?.pics[0]}
                      alt=""
                      className="object-cover w-full h-full flex-shrink-0"
                    />
                  </div>
                </div>
              );
            })}

          {/* Show Less button */}
          {showMore && (
            <button
              className="text-blue-500 underline"
              onClick={toggleShowMore}
            >
              Show Less
            </button>
          )}
        </>
      ) : (
        <h1 className="text-xl font-medium">No Past Trips</h1>
      )}
    </div>
  );
};

export default ReservationCrad;
