import React, { useState } from 'react';
import Grid from '@mui/material/Grid';

import ListingColor from 'components/Cards/ListingColor';

export default function AddTitle({ state, setState }) {
  const handleTitleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      title: e.target.value,
    }));
  };

  const handleDesChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      description: e.target.value,
    }));
  };
  const handleListingChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      listing: e.target.value,
    }));
  };
  return (
    <div>
      <Grid container spacing={5}>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "85vh", marginTop: '10vh' }}>
          <ListingColor
            bg='bg-color-darknavyblue'
            text='Give Your Property A Catchy Title & Description'
            color='text-[#FFFFFF]'
            btn='step 6'
          />
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12}
          style={{ marginTop: '10vh', marginBottom: '13vh', height: "83vh", overflowY: 'auto', overflowX: 'hidden' }}>
          <div className='mt-16 mx-4 '>
            <h3 className='text-2xl font-bold text-black mb-4 font-avenir'>
              Add your title here:
            </h3>
            <textarea
              id='story'
              name='story'
              rows='5'
              cols='33'
              onChange={handleTitleChange}
              value={state.title}
              placeholder='Type your title here'
              className='w-full h-[80px]  rounded-[15px] border-2 border-[#C1E1C2] shadow shadow-[#C1E1C2]  text-[20px] placeholder:text-[20px] placeholder:text-color-grey pl-5 pt-3'
            />
            <div className='text-sm mt-2'>{state?.title?.length}/30</div>
          </div>
          <div className='my-6 mx-4'>
            <h3 className='text-2xl font-bold text-black mb-4 font-avenir'>
              Add your description here:
            </h3>
            <textarea
              id='story'
              name='story'
              rows='5'
              cols='33'
              onChange={handleDesChange}
              value={state.description}
              placeholder='Type your description here'
              className='w-full h-[220px]  rounded-[15px] border-2 border-[#C1E1C2] shadow shadow-[#C1E1C2] text-[20px] placeholder:text-[20px] placeholder:text-color-grey pl-5 pt-3'
            />
            <div className='text-sm mt-2'>{state?.description?.length}/500</div>
          </div>
          <div className='my-6 mx-4'>
            <h3 className='text-2xl font-bold text-black mb-4 font-avenir'>
              Add this listing:
            </h3>
            <textarea
              id='story'
              name='story'
              rows='5'
              cols='33'
              onChange={handleListingChange}
              value={state.listing}
              placeholder='Type your listing'
              className='w-full h-[220px]  rounded-[15px] border-2 border-[#C1E1C2] shadow shadow-[#C1E1C2] text-[20px] placeholder:text-[20px] placeholder:text-color-grey pl-5 pt-3'
            />
            <div className='text-sm mt-2'>{state?.listing?.length}/500</div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
