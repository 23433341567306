import React, { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import Modal from "components/Modal/Modal";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import Rating from "react-rating";



export const GuestReview = ({ setSteps, pastTrips }) => {
  const [selectedTab, setSelectedTab] = useState("reviewsTo");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);

  console.log("pastTrips@@@@@@@@@@@@@@@@@@@@@@@@@", pastTrips)


  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  // Calculate total number of pages
  const totalPages = Math.ceil(pastTrips.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Get the items to be displayed on the current page
  const getDisplayItems = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return pastTrips.slice(startIndex, endIndex);
  };

  // Format booking date
  const formateBookingDate = (timestamp) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const bookDate = new Date(timestamp);
    return bookDate.toLocaleDateString(undefined, options);
  }

  // Format review date
  const formateReviewDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const reviewDate = new Date(date);
    return reviewDate.toLocaleDateString(undefined, options);
  };


  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const openModal = (data) => {
    setSelectedTrip(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedTrip(null);
    setIsModalOpen(false);
  };
  console.log("selectedTrip@@@@@@@@@@@@@@@@@@@@@@@@@", selectedTrip)
  console.log("getDisplayItems()", getDisplayItems())

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="text-xl font-medium text-center text-gray-500 border-b-2 border-gray-200">
          <ul className="flex flex-wrap -mb-px cursor-pointer">
            <li className="me-2 text-[#AFB5C1] w-[126px] hidden md:block"></li>
            <li className="me-2 text-[#AFB5C1]">
              <p
                onClick={() => handleTabClick("reviewsTo")}
                className={`inline-block p-4 border-b-2 ${selectedTab === "reviewsTo"
                  ? "border-yellow-500 rounded-t-lg text-black"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300"
                  }`}
              >
                Reviews To
              </p>
            </li>
            <li className="me-2 ">
              <p
                onClick={() => handleTabClick("history")}
                className={`inline-block p-4 border-b-2 ${selectedTab === "history"
                  ? "border-yellow-500 rounded-t-lg text-black"
                  : "border-transparent hover:text-gray-600 hover:border-gray-300"
                  }`}
              >
                History
              </p>
            </li>
          </ul>
        </div>

        <div className="flex flex-col gap-4 md:my-10 md:mx-32 mx-5">
          <p className="text-2xl font-medium">
            {selectedTab === "reviewsTo" && (
              <>
                <div className="flex ">
                  <button
                    className="flex items-center text-2xl font-medium text-blue-500 "
                    onClick={() => setSteps("profileDetails")}
                  >
                    <IoIosArrowBack />
                  </button>
                  <span> My Reviews</span>
                </div>

              </>
            )}
            {selectedTab === "history" && (
              <>
                <div className="flex ">
                  <button
                    className="flex items-center text-2xl font-medium text-blue-500 "
                    onClick={() => setSteps("profileDetails")}
                  >
                    <IoIosArrowBack />
                  </button>
                  <span>History</span>
                </div>
              </>
            )}
          </p>

          <div className="w-full h-auto py-5">
            {selectedTab === 'reviewsTo' && (
              <div className="w-full h-auto py-5">
                <div className="">

                  {getDisplayItems()
                    .filter(data => data.property_id.reviews && data.property_id.reviews.length > 0).length === 0 ? (
                    <p className="text-center text-lg text-gray-500">You haven't posted any reviews yet. Your reviews will appear here once you review a property you have stayed at.</p>
                  ) : (
                    getDisplayItems()
                      .filter(data => data.property_id.reviews && data.property_id.reviews.length > 0)
                      .map((data, index) => {
                        console.log("data with reviews:", data.property_id.reviews);
                        return (
                          <div key={index} className="mx-auto flex flex-col lg:flex-row bg-white rounded-xl overflow-hidden border border-[#C1E1C2] p-6 gap-4 justify-between mb-4">
                            <div className="flex flex-col gap-6 md:flex-row">
                              <div className="">
                                <img
                                  className="w-full h-full md:px-0 md:h-[170px] md:w-[250px] xl:w-[170px] object-cover rounded-2xl"
                                  src={data?.property_id?.pics[0]}
                                  alt="Profile"
                                />
                              </div>
                              <div className="flex flex-col gap-1">
                                <p className="font-normal text-base text-[#4B4B4B]">
                                  Booked on: {formateBookingDate(data?.timeStamp)}
                                </p>
                                <p className="text-2xl font-medium capitalize">{data?.property_id?.title}</p>
                                <p className="text-base text-[#AFB5C1] font-normal capitalize">
                                  Bathrooms: {data?.property_id?.spaceTypeDetail?.bathrooms}{" | "}
                                  Bedrooms: {data?.property_id?.spaceTypeDetail?.bedrooms}{" | "}
                                  Guests: {data?.property_id?.spaceTypeDetail?.guests}{" | "}
                                  Kitchen: {data?.property_id?.spaceTypeDetail?.kitchen}
                                </p>
                                <p className="text-xl text-[#C0392D] font-black ">{data?.property_id?.points} Points</p>
                                <p className="text-base text-[#4B4B4B] font-normal capitalize">
                                  {data?.property_id?.address}
                                </p>
                              </div>
                            </div>
                            <div className="w-full lg:w-[100px]">
                              <button onClick={() => openModal(data)} className="px-5 py-2 rounded-full bg-blue-500 text-white">Review</button>
                            </div>
                          </div>
                        );
                      })
                  )
                  }


                  {totalPages > 1 && (
                    <div className="flex items-center justify-end gap-3">
                      <button
                        onClick={handlePrevPage}
                        disabled={currentPage === 1}
                        className={`flex items-center gap-1 font-bold justify-center w-[38.48px] h-[38.48px] rounded-full  ${currentPage === 1 ? 'disabled' : ''} ${currentPage === 1 ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'}`}
                      >
                        <IoIosArrowBack className="text-xl" />
                      </button>

                      {[...Array(totalPages).keys()].map((page) => {
                        if (
                          page + 1 === 1 ||
                          page + 1 === currentPage ||
                          page + 1 === currentPage - 1 ||
                          page + 1 === currentPage + 1 ||
                          page + 1 === totalPages
                        ) {
                          return (
                            <button
                              key={page}
                              onClick={() => handlePageChange(page + 1)}
                              className={`${page + 1 === currentPage ? 'bg-yellow-500 text-white' : ' text-gray-700'} px-3 py-1 rounded-full focus:outline-none`}
                            >
                              {page + 1}
                            </button>
                          );
                        } else if (
                          page === 0 ||
                          page === totalPages - 2
                        ) {
                          return <span key={page} className="text-black ">...</span>;
                        }
                        return null;
                      })}

                      <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className={`flex items-center gap-1 justify-center w-[38.48px] h-[38.48px] rounded-full ${currentPage === totalPages ? 'disabled' : ''} ${currentPage === totalPages ? 'bg-gray-300 text-gray-600' : 'bg-blue-500 text-white'}`}
                      >
                        <IoIosArrowForward className="text-xl" />
                      </button>
                    </div>
                  )}

                </div>
              </div>
            )}
            {selectedTab === 'history' && <p>History</p>}
          </div>
        </div>
      </div>
      {selectedTrip && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <div className="my-5 max-h-[78vh] overflow-y-auto py-2 ">
            <div className="w-full h-full px-6 rounded-xl flex flex-col justify-start">
              <div>
                <div className="flex items-center gap-4">
                  <div className="w-12 h-12">
                    <img
                      src={selectedTrip?.userId?.pic}
                      alt="User Pic"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </div>
                  <div className="flex flex-col">
                    <h4 className="text-lg text-black font-semibold">
                      {selectedTrip?.userId?.fname + " " + selectedTrip?.userId?.lname}
                    </h4>
                    <span className="text-sm text-gray-400">{formateReviewDate(selectedTrip?.property_id?.reviews[0].date)}</span>
                  </div>
                </div>
                <div className="pt-3">

                  <Rating
                    initialRating={selectedTrip?.property_id?.reviews[0]?.rating}
                    readonly
                    emptySymbol={<FaRegStar color="#d3d3d3" />}
                    halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                    fullSymbol={<FaStar color="#ffd700" />}
                    fractions={2}
                  />
                </div>
              </div>
              <div className="mt-2">
                <p className="text-base text-gray-700 font-normal leading-7">
                  {selectedTrip?.property_id?.reviews[0]?.comment}
                </p>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
