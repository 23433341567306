import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { useLoginEmailAccount } from "hooks";
import GoogleApi from "components/SocialLogin/GoogleApi";
import FacebookApi from "components/SocialLogin/FacebookApi";
import { RiArrowDropDownFill } from "react-icons/ri";
import CircularProgress from "@mui/material/CircularProgress";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import adminLogin from '../../../assets/img/adminLogin.png';
import { AdminLoginEmailAccount } from "hooks/adminLoginEmailAccount";


function AdminLogin() {
    let navigate = useNavigate();
    const { mutateAsync: loginEmailAccount, isLoading } = AdminLoginEmailAccount();
    const [showPassword, setShowPassword] = useState(false);
    //   const [dropdownlogin, setDropdownlogin] = useState(false);
    //   const [loginAs, setLoginAs] = useState("Guest");
    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // setDropdownlogin(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const formik = useFormik({
        initialValues: {
            email: "",
            pass: "",
            // loginAs: ''
        },
        validationSchema: Yup.object().shape({
            email: Yup.string() // Change username to email
                .email("Invalid email format")
                .required("Email is Required"),
            pass: Yup.string()
                .required("Password is Required")
        }),
        onSubmit: async (values) => {
            try {
                const response = await loginEmailAccount(values);
                navigate("/admin/dashboard"); // Redirect after successful login
            } catch (error) {
                console.error("Login failed:", error);
            }
        }
    });
    // console.log("kjdshjk",loginAs)
    return (
        <div className="bg-gray-200">
            <div className=" flex  flex-row-reverse rounded-lg items-center justify-center sm:w-[80%] mx-auto overflow-hidden ">
                {/* <Grid container direction="row-reverse" >
       
        
        <Grid item lg={5} md={5} sm={12} xs={12}> */}
                <div className="w-full rounded-md flex items-center h-screen justify-center  ">
                    <div className="relative justify-center p-4 w-[100%]  h-full flex flex-col bg-white min-w-0 break-words  rounded-tr-xl rounded-br-xl bg-auth ">
                        <div className="px-6 py-6 mb-0 rounded-t ">

                            <div className="mb-3 text-center">
                                <h1 className="text-2xl font-bold text-grey-500 " style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}>Admin Login</h1>

                            </div>

                            {/* <hr className="mt-6 border-b-1 border-grey-300" /> */}
                        </div>
                        <div className="flex-row px-4 lg:px-10 py-10 justify-center items-center pt-0 w-full">
                            <form
                                onSubmit={formik.handleSubmit}
                            >
                                <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2 font-bold text-gray-500"
                                        htmlFor="email" // Change htmlFor to "email"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        id="email" // Change id to "email"
                                        name="email" // Change name to "email"
                                        type="text"
                                        className="w-full px-4 py-3 mt-2 font-medium text-gray-700 placeholder-gray-400 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
                                        placeholder="Enter Email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="py-1 text-sm text-red-500">
                                            {formik.errors.email === 'Email is Required' ? 'Email is required' :
                                                formik.errors.email === 'Invalid email format' ? 'Invalid email format' :
                                                    formik.errors.email}
                                        </div>
                                    ) : null}
                                </div>

                                {/* <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2  font-bold text-gray-500"
                                        htmlFor="username"
                                    >
                                        Email Address
                                    </label>
                                    <input
                                        id="username"
                                        name="username"
                                        type="text"
                                        className="w-full px-4 py-3 mt-2 font-medium text-gray-700 placeholder-gray-400 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500" placeholder="Enter Email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.username}
                                    />
                                    {formik.touched.username && formik.errors.username ? (
                                    <div className="py-1 text-sm text-red-500">
                                        Displaying specific error messages
                                        {formik.errors.username === 'Email is Required' ? 'Email is required' :
                                            formik.errors.username === 'Invalid email format' ? 'Invalid email format' :
                                                formik.errors.username}
                                    </div>
                                    ) : null}
                                </div> */}


                                <div className="relative w-full mb-3">
                                    <label
                                        className="block mb-2  font-bold text-gray-500"
                                        htmlFor="grid-password"
                                    >
                                        Password
                                    </label>
                                    <div className="relative">
                                        <input
                                            name="pass"
                                            id="pass"
                                            type={showPassword ? "text" : "password"}
                                            className="w-full px-4 py-3 mt-2 font-medium text-gray-700 placeholder-gray-400 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500" placeholder="Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.pass}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {/* {showPassword ? (
                                            <IoEyeOutline className="text-[#929293] text-xl" />
                                            {/* ) : ( */}
                                            <IoEyeOffOutline className="text-[#929293] text-xl" />
                                            {/* )} } */}
                                        </div>
                                    </div>
                                    {/* {formik.touched.pass && formik.errors.pass ? ( */}
                                    <div className="py-1 text-sm text-red-500">
                                        {/* {formik.errors.pass} */}
                                    </div>
                                    {/* ) : null} */}
                                </div>

                                {/* <p
                href=""
                className="float-left my-4 font-semibold text "
              >
                <input type="checkbox" className="" />
                Remember me
              </p>
              <a
                href="/auth/forgotpassword"
                className="flex float-right my-4 font-semibold text "
              >
                Forgot Password
              </a> 
              */}

                                <div className="flex items-center justify-between pt-10">
                                    <div className="flex items-center gap-3">
                                        <input type="checkbox" />
                                        <p className="text-sm font-medium text-black">Remember me</p>
                                    </div>
                                    {/* <Link to="/auth/forgotpassword" className="text-sm font-medium">
                                        Forgot Password
                                    </Link> */}
                                </div>

                                <div className="mt-6 text-center">
                                    {isLoading ? (
                                        <CircularProgress />
                                    ) : (
                                        <>
                                            <button
                                                //  className="btn-styl "
                                                className="w-full px-6 py-3 mb-1 mr-1 font-bold text-white bg-blue-500 rounded-full shadow text-md hover:shadow-lg "
                                                type="submit"
                                            >
                                                LOG IN
                                            </button>
                                        </>
                                    )}
                                </div>
                                {/* <hr className="my-4 separator" /> */}


                            </form>


                        </div>
                    </div>
                </div>
                {/* </Grid>
        <Grid item lg={5} md={5} sm={10} xs={10}> */}

                <div className="relative hidden w-full h-screen my-5 rounded-bl-lg rounded-tl-lg md:block bg-blue-200">

                    <div className="h-full flex items-center justify-center ">
                        <img
                            className="object-cover  z-20"
                            // src={require("assets/img/whitelogo.png")}
                            src={adminLogin}
                        />
                    </div>
                </div>
                {/* </Grid>
      </Grid> */}
            </div>

        </div>
    );
}

export default AdminLogin;
