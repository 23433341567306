import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/img/logo3.png";
import { GoHome } from "react-icons/go";
import { FaArrowLeft } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import AdminSidebarSteps from './AdminSidebarSteps';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import AdminNav from 'pages/admin/Header/AdminNav';
import dashboardLogo from '../../assets/img/AdminSidebar Logos/dashboardLogo.png'
import managementLogo from '../../assets/img/AdminSidebar Logos/managementLogo.png'
import propertiesLogo from '../../assets/img/AdminSidebar Logos/propertiesLogo.png'
import referalLogo from '../../assets/img/AdminSidebar Logos/referalLogo.png'
import reservationLogo from '../../assets/img/AdminSidebar Logos/reservationLogo.png'
import reviewsLogo from '../../assets/img/AdminSidebar Logos/reviewsLogo.png'
import settingLogo from '../../assets/img/AdminSidebar Logos/settingLogo.png'
import ticketingLogo from '../../assets/img/AdminSidebar Logos/ticketingLogo.png'
import transactionLogo from '../../assets/img/AdminSidebar Logos/transactionLogo.png'
import permissionLogo from '../../assets/img/AdminSidebar Logos/permissionLogo.png'
import websiteLogo from '../../assets/img/AdminSidebar Logos/websiteLogo.png'
import { localStorageData, Logout } from "services/auth/localStorageData";
import userServices from 'services/httpService/userAuth/userServices';

function SidebarAdmin() {
  const [active, setActive] = useState(1);
  const [Dashboard, setDashboard] = useState([]);
  const [Management, setManagement] = useState([]);
  const [Properties, setProperties] = useState([]);
  const [Reservation, setReservation] = useState([]);
  const [Financial, setFinancial] = useState([]);
  const [Reviews, setReviews] = useState([]);
  const [Settings, setSettings] = useState([]);
  const [Supports, setSupports] = useState([]);
  const [Refund, setRefund] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [blogPost, setBlogPost] = useState([]);
  const [showReservationItems, setShowReservationItems] = useState(false);
  const [showBlogsTabs, setShowBlogsTabs] = useState(false)
  const [showSettings, setshowSettings] = useState(false);

  let navigate = useNavigate();
  const [collapseShow, setCollapseShow] = React.useState('hidden');

  const handleActive = (num) => {
    setActive(num);
  };
  const handleReservationClick = () => {
    setShowReservationItems((prev) => !prev);
    // handleActive(4)
  };
  const handleBlogTabClick = () => {
    setShowBlogsTabs((prev) => !prev);
    // handleActive(4)
  };
  const handleSetting = () => {
    setshowSettings((prev) => !prev);
    // handleActive(7)
  };
  const handleClick = (num) => {
    handleActive(num)
    setCollapseShow('hidden')
  }

  return (
    <>
      <div className='flex flex-col lg:flex-row bg-[#f8f7fa]'>
        <div className='w-full lg:w-[330px] flex flex-col justify-between bg-[#01233F] lg:h-[100vh] h-auto  md:overflow-y-scroll overflow-auto '>
          <div>
            <nav className='md:left-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap shadow bg-[#01233F] flex flex-wrap items-center justify-between   lg:w-64 w-full z-10 py-4 '>
              <div className='lg:flex-col lg:items-stretch lg:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full '>
                {/* Toggler */}
                <button
                  className='cursor-pointer text-white opacity-50 lg:hidden px-3 py-1 text-2xl leading-none rounded border border-solid border-transparent'
                  type='button'
                  onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
                >
                  <i className='fas fa-bars'></i>
                </button>
                {/* Brand */}
                <Link
                  className='md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4'
                  to='/'
                >
                  {/* Admin Bnbyond */}
                  <img src={logo} alt='' className='' />
                </Link>
                {/* Collapse */}
                <div
                  className={
                    'lg:flex lg:flex-col md:items-stretch bg-[#01233F] lg:bg-transparent opacity-100 lg:relative md:mt-4  shadow absolute top-0 left-0 right-0 z-40  overflow-x-hidden h-auto items-center flex-1 rounded ' +
                    collapseShow
                  }
                >
                  {/* Collapse header */}
                  <div className='md:min-w-full lg:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200'>
                    <div className='flex flex-wrap'>
                      <div className='w-6/12'>
                        <Link
                          className='md:block text-left md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0'
                          to='/'
                        >
                          BESCHDULED
                        </Link>
                      </div>
                      <div className='w-6/12 flex justify-end'>
                        <button
                          type='button'
                          className='cursor-pointer text-white opacity-50 lg:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                          onClick={() => setCollapseShow('hidden')}
                        >
                          <i className='fas fa-times'></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <ul
                    onClick={() => handleClick(1)}
                    className={`flex lg:items-center items-start px-4 py-4 cursor-pointer text-white no-underline md:flex-row md:min-w-full flex-col list-none ${active === 1 ? "text-color-white" : "border-b-transparent"
                      }`}
                  >
                    <li className="flex items-center text-white font-Avenir text-base font-medium opacity-70">
                      <img src={dashboardLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                      Dashboard
                    </li>
                  </ul>
                  <ul
                    onClick={() => handleClick(2)}
                    className={`flex lg:items-center items-start px-4 py-4 cursor-pointer text-white no-underline md:flex-row md:min-w-full flex-col list-none ${active === 2 ? "text-color-white" : "border-b-transparent"
                      }`}
                  >
                    <li className="flex items-center  text-white font-Avenir text-base font-medium opacity-70">
                      <img src={managementLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                      Management
                    </li>
                  </ul>


                  <ul
                    onClick={() => handleClick(3)}
                    className={`flex lg:items-center items-start px-4 py-4 cursor-pointer text-white no-underline md:flex-row md:min-w-full flex-col list-none ${active === 3 ? "text-color-white" : "border-b-transparent"
                      }`}
                  >
                    <li className="flex items-center text-white font-Avenir text-base font-medium opacity-70">
                      <img src={propertiesLogo} className='mr-2 w-[25px] h-[25px] text-white' />

                      Properties
                    </li>
                  </ul>

                  <ul className="items-center px-4 cursor-pointer text-white no-underline md:min-w-full list-none">
                    <li className="flex py-4 items-center justify-between text-white font-Avenir text-base font-medium opacity-70" onClick={handleReservationClick}>
                      <div className="flex items-center">
                        <img src={reservationLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                        Reservation
                      </div>
                      {/* Conditional rendering of arrow icon */}
                      {showReservationItems ? <FaChevronUp /> : <FaChevronDown />}
                    </li>
                    {showReservationItems && (
                      <>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(4)}>
                          <img src={reservationLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Reservation Management
                        </li>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(5)}>
                          <img src={transactionLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Financial Transactions
                        </li>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(6)}>
                          <img src={reviewsLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Reviews / Reporting
                        </li>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(12)}>
                          <img src={reviewsLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Refund
                        </li>

                      </>
                    )}
                  </ul>

                  <ul className="items-center px-4 cursor-pointer text-white no-underline md:min-w-full list-none">
                    <li className="flex py-4 items-center justify-between text-white font-Avenir text-base font-medium opacity-70" onClick={handleBlogTabClick}>
                      <div className="flex items-center">
                        <img src={reservationLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                        Blog
                      </div>
                      {/* Conditional rendering of arrow icon */}
                      {showBlogsTabs ? <FaChevronUp /> : <FaChevronDown />}
                    </li>
                    {showBlogsTabs && (
                      <>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(11)}>
                          <img src={reservationLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Upload Blog
                        </li>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(13)}>
                          <img src={reservationLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          All blog
                        </li>
                      </>
                    )}
                  </ul>

                  <ul className=" items-center px-4 cursor-pointer text-white no-underline md:min-w-full list-none">
                    <li className="flex py-4 items-center justify-between text-white font-Avenir text-base font-medium opacity-70" onClick={handleSetting}>
                      <div className='flex items-center'>
                        <img src={websiteLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                        Website Settings
                      </div>
                      {/* Conditional rendering of arrow icon */}
                      {showSettings ? <FaChevronUp /> : <FaChevronDown />}
                    </li>
                    {showSettings && (
                      <>
                        {/* <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(7)}>
                          <img src={referalLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Referral Programs
                        </li> */}
                        {/* <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(8)}>
                          <img src={ticketingLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Supports / Ticketing
                        </li> */}
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(9)}>
                          <img src={permissionLogo} className='mr-2 w-[25px] h-[25px] text-white ' />
                          Roles & Permissions
                        </li>
                        <li className="flex py-4 items-center text-white font-Avenir text-base font-medium opacity-70" onClick={() => handleClick(10)}>
                          <img src={settingLogo} className='mr-2 w-[25px] h-[25px] text-white' />
                          Settings
                        </li>

                      </>
                    )}
                  </ul>

                  <li onClick={() => { Logout(); navigate("/admin/login"); }}
                    className="flex py-4 px-4 items-center text-white font-Avenir text-base font-medium opacity-70">
                    <FaArrowLeft className="mr-2 w-[25px] h-[25px] text-red-600" />
                    <button
                      type='button'
                      className=''
                    >
                      Logout
                    </button>
                  </li>
                  {/* </ul> */}

                </div>
              </div>
            </nav>
          </div>
        </div>
        <div className='border w-full md:h-[100vh] h-auto  md:overflow-y-scroll overflow-auto '>
          <AdminNav />
          <AdminSidebarSteps
            active={active}
            Dashboard={Dashboard}
            Management={Management}
            PropertiesDatials={Properties}
            ReservationDatials={Reservation}
            Financial={Financial}
            Reviews={Reviews}
            Settings={Settings}
            // Referral={Referral}
            Supports={Supports}
            Roles={Roles}
            Refund={Refund}
            blogPost={blogPost}


          />

        </div>
      </div>

    </>
  );
}

export default SidebarAdmin;
