import React from 'react';
import GoogleLogin from 'react-google-login';
import { useNavigate } from 'react-router-dom';

import { ImageEndPoint, googleapi } from 'config/config';
import { useCreateGoogleAccount } from 'hooks/useCreateGoogleAccount';
function GoogleApi(props, { type = 'Signup', handleClose }) {
  let navigate = useNavigate();
  const { mutateAsync: createGoogleAccount } = useCreateGoogleAccount(); // Custom hook to create a Google account

  // function to handle Google login success
  async function GoogleSuccessRequest(data) {

    // Create a new object with user data from Google login response
    let newdata = {
      email: data.profileObj.email,
      fname: data.profileObj.givenName,
      id: data.googleId,
      pic: data.profileObj.imageUrl,
    };

    // Call the function to create a Google account with the new data
    const response = await createGoogleAccount(newdata);
    console.log("respone data ;", response)

    if (response.status) {
      let res = response.data;

      res['pic'] = ImageEndPoint + res.pic;

      navigate('/')
    }

  }

  async function GoogleFailureRequest(data) {
  }

  return (
    <GoogleLogin

      clientId={googleapi}
      render={(renderProps) => (
        <button
          className='bg-white text-gray-900 rounded-full my-2 border border-gray-200 uppercase shadow-sm font-semibold  px-3 py-2 w-full items-center justify-center  outline-none focus:outline-none mr-2  hover:shadow-md ease-linear transition-all duration-150'
          onClick={renderProps.onClick}
        >
          <i className='fab fa-google'></i>
          <span className='ml-4'> {props.name}</span>
        </button>
      )}
      onSuccess={GoogleSuccessRequest}
      onFailure={GoogleFailureRequest}
      cookiePolicy={'single_host_origin'}
    ></GoogleLogin>
  );
}

export default GoogleApi;
