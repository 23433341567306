import AreaGraph from 'components/Graphs/AreaGraph'
import React from 'react'
import { FaArrowTrendUp } from 'react-icons/fa6'
import australiaFlag from '../../../assets/img/australiaFlag.png'
import percentage from '../../../assets/img/percentage.png'
import visa from '../../../assets/img/visa.png'
import dp from '../../../assets/img/dp.png'
import PiGraph from '../../../components/Graphs/PiGraph';
import { BsThreeDotsVertical } from "react-icons/bs";
import { SlHome } from 'react-icons/sl'
import { FaRegUser } from 'react-icons/fa'
import searchIcon from '../../../assets/img/searchIcon.png'
import { VscSettings } from "react-icons/vsc";
import { MdOutlineFileDownload } from "react-icons/md";
import Dropdown from '../UserManagement/Dropdown'
import icon3 from "assets/img/filter.png"
import icon4 from "assets/img/searchbar.png"
import icon5 from "assets/img/export.png"
import { useState } from 'react'
import Pagination from 'components/Pagination/Pagination'


const FinancialTransactions = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(4);


    const transactions = [
        { id: 1, transactionId: '#12345', date: 'Feb 29, 2024', amount: '250 Points', type: 'Service Fee', payment: 'visa', description: 'Property Rental' },
        { id: 1, transactionId: '#12345', date: 'Feb 29, 2024', amount: '250 Points', type: 'Service Fee', payment: 'visa', description: 'Property Rental' },
        { id: 1, transactionId: '#12345', date: 'Feb 29, 2024', amount: '250 Points', type: 'Service Fee', payment: 'visa', description: 'Property Rental' },
        { id: 1, transactionId: '#12345', date: 'Feb 29, 2024', amount: '250 Points', type: 'Service Fee', payment: 'visa', description: 'Property Rental' },
        { id: 1, transactionId: '#12345', date: 'Feb 29, 2024', amount: '250 Points', type: 'Service Fee', payment: 'visa', description: 'Property Rental' },
        { id: 1, transactionId: '#12345', date: 'Feb 29, 2024', amount: '250 Points', type: 'Service Fee', payment: 'visa', description: 'Property Rental' },

    ];

    // logic for pagination
    const totalPages = Math.ceil(transactions.length / itemsPerPage);
    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentPageData = transactions.slice(firstIndex, lastIndex);

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div>
            <div className='flex md:flex-row flex-col justify-between items-center px-5  py-3 '>
                <div>
                    <span>
                        <h1 className='font-bold text-[24px]'>Financial Transactions</h1>
                    </span>
                </div>
            </div>

            {/* the commented code is for future use */}

            {/* <div className='w-[90%] flex flex-col  border-2 border-[#DFF7E9] rounded-xl gap-2 mx-5 '>
                <div className='flex justify-between items-center px-5 pt-2'>
                    <div>
                        <h1 className='text-[16px]'>Overall Earning</h1>
                    </div>
                    <div>
                        <h1 className='bg-gray-100 rounded-full px-4 py-1.5'>Last 30 Days ^</h1>
                    </div>
                </div>
                <hr />
                <div className='flex gap-10 px-5 items-center bg-gray-300 py-3'>
                    <div>
                        <h1 className='text-gray-500 pb-2 font-bold'>Total Earning</h1>
                        <div className='flex md:flex-row flex-col gap-2'>
                            <span>
                                <h1 className='font-bold text-[14px]'>846 Points</h1>
                            </span>
                            <span className='flex md:gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <p className='text-[#40CA5E]'>15.00%</p>
                            </span>
                        </div>
                    </div>
                    <div >
                        <h1 className='text-gray-500 pb-2 font-bold'>Earning Breakdown</h1>
                        <div className='flex md:flex-row flex-col gap-2'>
                            <span>
                                <h1 className='font-bold text-[14px]'>84k Points</h1>
                            </span>
                            <span className='flex md:gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <p className='text-[#40CA5E]'>15.00%</p>
                            </span>
                        </div>
                    </div>

                </div>

                <div>
                    <AreaGraph />
                </div>

                <div className='flex border-t border-gray-300'>
                    <div className='lg:w-[15%]   '>
                        <span className='flex flex-col gap-1  px-5 py-2'>
                            <h1 className='text-gray-500'>Demo</h1>
                            <h1 className='font-bold'>846</h1>
                        </span>
                    </div>
                    <div className='flex flex-wrap w-full'>

                        {
                            [1, 2, 3, 4, 5, 6].map((index, demo) => (
                                <div className='border-l border-gray-300 sm:w-1/6 '>
                                    <span className='flex flex-col gap-1  px-5 py-2'>
                                        <h1 className='text-gray-500'>Demo</h1>
                                        <h1 className='font-bold'>84k Points</h1>
                                    </span>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 px-5 gap-5 my-5'>

                <div className='flex flex-col w-full border-2 border-[#DFF7E9] rounded-xl '>
                    <div className='flex items-center justify-between p-2'>
                        <div>
                            <h1 className='font-bold'>Earning by Countries</h1>
                        </div>
                        <div className='flex items-center gap-4'>
                            <Dropdown />
                            <BsThreeDotsVertical />
                        </div>
                    </div>
                    <hr />
                    <div>
                        {currentPageData.map((data, index) => (
                            <div className='flex justify-between p-3 border-b'>
                                <div className='flex items-center gap-3'>
                                    <div>
                                        <img src={australiaFlag}></img>
                                    </div>
                                    <div className='flex flex-col'>
                                        <h1 className='font-bold'>15,54k Points</h1>
                                        <p className='text-gray-400'>Australia</p>
                                    </div>

                                </div>
                                <div className='flex items-center justify-center gap-2'>
                                    <FaArrowTrendUp className='text-[#40CA5E]' />
                                    <h1 className='text-[#40CA5E]'>15.00%</h1>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
                <div className='border-2 border-[#DFF7E9] rounded-xl'>
                    <div className='flex justify-between items-center px-5 py-2'>
                        <div>
                            <h1 className='font-bold'>States</h1>
                        </div>
                        <div>
                            <Dropdown />
                        </div>
                    </div>
                    <hr />

                    <div>
                        <PiGraph />
                    </div>
                </div>

                <div className='flex flex-col border-2 border-[#DFF7E9] rounded-xl'>
                    <div className='flex justify-between items-center px-5 py-2'>
                        <div>
                            <h1 className='font-bold'>Support Tracker</h1>
                        </div>
                        <div>
                            <BsThreeDotsVertical />
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center p-4 gap-3'>
                        <h1 className='font-bold'>Admin</h1>
                        <p className='text-gray-400 text-sm'>646</p>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center p-4 gap-3'>
                        <h1 className='font-bold'>Guest</h1>
                        <p className='text-gray-400 text-sm'>646</p>
                    </div>
                    <hr />
                    <div className='flex justify-between items-center p-4 gap-3'>
                        <h1 className='font-bold'>Host</h1>
                        <p className='text-gray-400 text-sm'>646</p>
                    </div>
                    <hr />
                    <div className='flex justify-between px-5 py-7 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className='flex flex-col'>
                                <h1 className='text-gray-400'>On-time Completion Rate</h1>
                                <p className='font-bold text-[32px]'>50%</p>
                            </div>
                        </div>
                        <div>
                            <img src={percentage}></img>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className='mx-5 my-3 flex flex-col mt-6 rounded-tl-[15px] rounded-tr-[15px] border border-gray-300 bg-white '>
                <div className='w-full md:p-4 p-2 flex justify-between gap-2 bg-white  rounded-t-xl items-center '>
                    <div className=''>
                        <p className='font-medium sm:text-lg text-sm'>Transactions</p>
                    </div>
                    <div className='flex  space-x-2'>
                        <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px]  border border-gray-300 rounded-md'>
                            <div>
                                <img src={icon3} />
                            </div>
                            <div>
                                <p className='font-normal text-xs text-gray-600'>Fillter</p>
                            </div>
                        </div>
                        <div className='flex justify-between sm:p-4 p-2 items-center  w-[100px] h-[45px] border border-gray-300 rounded-md'>
                            <div>
                                <img src={icon5} />
                            </div>
                            <div>
                                <p className='font-normal text-xs text-gray-600'>Export</p>
                            </div>
                        </div>

                        <div className="relative md:w-64  ">
                            <input
                                type="text"
                                placeholder='Search'
                                className='border border-gray-400  sm:py-3 sm:px-4  py-3 text-xs rounded-md w-full'
                            />
                            <img
                                src={icon4}
                                alt="search icon"
                                className="absolute right-0 top-1/2 transform -translate-y-1/2 mr-3 cursor-pointer"
                            />
                        </div>
                    </div>
                </div>

                <div className='overflow-auto'>
                    <table className="table-auto   w-full bg-white ">
                        <thead>
                            <tr className='bg-[#fafbff]'>
                                <th className="px-4 py-2 border-t border-b text-left">
                                    <input type="checkbox" class="mr-2 border border-red-500" />
                                    Transaction ID
                                </th>
                                <th className="px-4 py-2 text-left border-t border-b">Date and Time</th>
                                <th className="px-4 py-2 border-t text-left border-b">Transaction Type</th>
                                <th className="px-4 py-2 border-t  text-left border-b">Amount</th>
                                <th className="px-4 py-2 border-t text-left border-b">Payment</th>
                                <th className="px-4 py-2 border-t text-left border-b">Description</th>
                                <th className="px-4 py-2 border-t text-left border-b">Source</th>
                                <th className="px-4 py-2 border-t text-left border-b"></th>

                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData.map((_, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <input type="checkbox" className="mr-2" />
                                        <span className="font-bold text-sm text-blue-500">#12345</span>
                                    </td>

                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <div className='flex flex-col'>
                                            <span className='font-bold'>Feb 29, 2024</span>
                                            <span className="font-medium text-xs leading-4 text-gray-400">03:00 AM</span>
                                        </div>
                                    </td>
                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <div className="flex flex-col">
                                            <span className='font-bold'>Service Fee</span>
                                        </div>
                                    </td>

                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <span className="font-bold">$250</span>
                                    </td>

                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <div className='flex gap-2'>
                                            <img src={visa}></img>
                                        </div>
                                    </td>
                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <div className='flex flex-col'>
                                            <span className='text-blue-500'>View Details</span>
                                        </div>
                                    </td>
                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <span className='font-bold text-gray-500'>Property Rental</span>
                                    </td>
                                    <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                        <BsThreeDotsVertical />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>


                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />

            </div>




        </div>

        // </div>
    )
}

export default FinancialTransactions
