import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Axios from "axios";
import ListingColor from "components/Cards/ListingColor";
import Modal from "components/Modal/Modal";
import referalpoint from '../../../assets/img/referalpoint.png'

export default function SetPricing({ state, setState }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("guestPrice");
  const [points, setPoints] = useState(0);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (state.userCurrency) {
      selectCurrency(state.userCurrency);
    }
  }, []);

  useEffect(() => {
    // Calculate the service fee whenever the guestPrice changes
    if (selectedState === 'guestPrice') {
      setPoints(state[selectedState]);
    } else {
      setPoints(state[selectedState]);
    }
  }, [selectedState, state]);

  // Function to convert an input value based on the selected currency rate.
  function convert(value) {
    setState((prevState) => ({
      ...prevState,
      input: value,
    }));
    var rate = state.info[state.userCurrency];

    setState((prevState) => ({
      ...prevState,
      output: value * rate,
    }));
  }

  // Function to select a currency and fetch the latest exchange rates.
  const selectCurrency = (value) => {
    setState((prevState) => ({
      ...prevState,
      from: value,
    }));

    // Fetch the latest exchange rates for the selected currency using Axios
    Axios.get(
      `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${value}.json`
    ).then((res) => {
      setState((prevState) => ({
        ...prevState,
        info: res.data[value],
      }));
    });
  };

  const handleSave = () => {
    setIsModalOpen(false);
  };

  const modalValue = () => {
    return points;
  };
  let total = parseInt(state.guestPrice) + parseInt(state.cleaningFee) + parseInt(state.amenitiesFee);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "82vh", marginTop: '10vh' }}>
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Set your Pricing"
            color="text-[#FFFFFF]"
            btn='step 8'
          />
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}
          style={{ marginTop: '13vh', paddingTop: "35vh", height: "82vh", overflowY: 'auto', overflowX: 'hidden' }}
        >
          <div className=" xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0">
            <h1 className="my-2 text-xl font-bold">Now set your price:</h1>
            <div className="">
              <div className="border border-color-grey bg-color-grey/50 flex justify-center items-center flex-col h-[400px] rounded-md p-4">

                <div className="flex items-center justify-center gap-10 pb-10">
                  <img
                    src={require("assets/img/coin.png")}
                    className="relative w-14 h-14 left-4 top-7"
                    alt=""
                  />
                  <p className="text-[130px] font-bold">{points}</p>
                  <img
                    src={require("assets/img/edit.png")}
                    className="relative w-12 h-12 cursor-pointer -left-8 top-9"
                    alt=""
                    onClick={openModal}
                  />
                </div>
                <div>
                  <select
                    name="currency"
                    className="block mx-auto my-2 bg-transparent outline-none text-color-darkgrey/50"
                    id="currency"
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                    }}
                  >
                    <option className="bg-white" value="guestPrice">
                      Guest price before taxes {state.guestPrice} Points
                    </option>
                    <option className="bg-white" value="discountPrice">
                      Discount Price {state.discountPrice} Points
                    </option>
                    <option className="bg-white" disabled value="serviceFee">
                      Bnbyond services fee (3%)
                    </option>
                    <option className="bg-white" value="cleaningFee" disabled>
                      Cleaning fee {state.cleaningFee} Points
                    </option>

                    <option className="bg-white" value="amenitiesFee" disabled>
                      Amenities fee {state.amenitiesFee} Points
                    </option>

                  </select>
                </div>

              </div>
              <div className="flex flex-col md:flex-row gap-4 md:gap-2 items-center justify-center my-2 px-3 md:px-0 md:mr-4">
                <p className="flex items-center text-sm md:text-lg px-1 md:px-4 py-2 border rounded-full border-color-grey">
                  <img
                    src={require("assets/img/yellowloc.png")}
                    className="mr-2"
                    alt=""
                  />
                  Compare similar listings $45 - $67
                </p>
                <p className="ml-2 underline cursor-pointer">
                  Learn more about pricing
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="min-h-full flex flex-col items-center justify-center">
          <img src={referalpoint}></img>
          <h2 className="lg:text-[36px] xs:text-[29px] py-10 font-medium leading-[41.98px] s text-center text-black font-avenir" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
            Enter your price
          </h2>

          <input
            type="number"
            className="input-styl w-[70%] rounded-md pl-3 pr-10 py-2" // Added padding for the icon
            placeholder="Enter the amount in points"
            value={state[selectedState]}
            onChange={(e) => {
              const newState = { ...state };
              newState[selectedState] = e.target.value;
              setState(newState);
              convert(e.target.value);
            }}
          />
          <button className="w-[50%] h-12 uppercase text-center py-2 px-2 rounded-full bg-[#2459BF] text-white text-sm font-semibold mt-5" onClick={handleSave}>Save</button>
        </div>
      </Modal>
    </div>
  );
}
