import React from 'react'
import { ButtonOutlined } from 'common/buttons/buttonOutlined'

const PrivacyAndPolicy = () => {
  return (
    <>
      <div className='flex flex-col md:flex-row p-12 gap-5'>

        <div className='w-full md:w-[60%]'>
          <h1 className='font-medium text-4xl'>Privacy policy</h1>
          <div className=' text-gray-800'>
            <p className='font-normal text-sm mt-2 text-gray-800'>Welcome to bnbyoud privacy policy.</p>
          </div>
          <div className=' text-gray-800'>
            <p className='font-medium text-gray-700'>Last Updated: January 25, 2023</p>
            <p className='font-normal text-sm'>bnbyond exists to help build connections between people and make the world more open and inclusive. In short—to build a world where anyone can belong anywhere. We are a community built on trust. A fundamental part of earning that trust means being clear about how we use your information and protecting your human right to privacy.</p><br />

          </div>
          <div className=' text-gray-800 font-normal text-sm'>

            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit reiciendis fuga aperiam soluta! Fugit, tempora magni! Corporis voluptate error earum possimus repellat!</p><br />
            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolore deserunt sequi nostrum sed quibusdam! Officia et aliquam dolore maxime sed impedit quisquam delectus provident illum, labore rem error praesentium accusamus, placeat ex voluptatum laborum. Ipsa ipsum autem vel omnis fugiat amet, dignissimos cupiditate culpa laudantium aperiam maxime, assumenda facere neque quidem! Veritatis ea, voluptatem laborum dolore eum nam velit libero. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestiae, veritatis in. Commodi veritatis quod distinctio excepturi mollitia quisquam amet sunt?</p><br />
            <p>1. Lorem ipsum</p>
            <p>1.1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.2. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae.</p><br />
            <p>1.3. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae.</p><br />
            <p>1.4. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.5. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.6. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,</p><br />
            <p>1.7. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet, </p><br />
            <p>1.8. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet</p><br />
            <p>1.9. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ultricies ullamcorper magna, eget commodo nibh tincidunt a. Vivamus interdum nisi risus, a volutpat nisl volutpat vitae. Morbi nec sapien ac odio ultrices interdum. Pellentesque a tortor molestie, feugiat nulla quis, suscipit urna. Fusce ac erat euismod, blandit orci et,Lorem ipsum dolor sit amet,</p><br />

          </div>
        </div>

        <div className="w-full md:w-[40%] h-52 border	border-color: inherit rounded-2xl p-6">
          <h2 className="text-lg font-medium mb-2">Need to get in touch?</h2>
          <p className="text-gray-600 mb-2 text-sm font-normal">We’ll start with some questions and get you to the right place.</p>
          <div className='md:w-[50%] text-base font-bold'>
            <ButtonOutlined text="contact us" />
          </div>
          <p className="text-gray-600 mb-2 mt-4 text-sm font-normal">You can also <span className='text-black font-semibold text-decoration-line: underline'><a href="#">give us feedback.</a></span></p>

        </div>
      </div>


    </>
  )
}

export default PrivacyAndPolicy
