import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useState } from "react";
import { ImageEndPoint } from "config/config";
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { localStorageData, Logout } from "services/auth/localStorageData";
import Grid from "@mui/material/Grid";
import { ButtonWithoutBackground } from "common/buttons/buttonWithoutBackgourd";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import dayjs from 'dayjs';
import GuestAndRoomSelector from "components/GuestAndRoomSelector/GuestAndRoomSelector";
import { PaymentCard } from "components/Cards/PaymentCard";
// import visaIcon from "../../../assets/img/visa.png"
import visaIcon from "../../../assets/img/cardTypes.jpg"
import { storeLocalData } from "services/auth/localStorageData";

const currentDate = dayjs();
function ReserveDetails() {
  const [paymentType, setPaymentType] = useState('');
  const [isSelectVisible, setIsSelectVisible] = useState({
    selectData: false,
    selectGuest: false,
  });
  const [showAddCard, setShowAddCard] = useState(false)
  const [tripe, setTripe] = useState({
    message: '',
    phoneNumber: '',
  });
  const navigate = useNavigate();
  const [value, setValue] = React.useState(5);
  const [allPost, setallPost] = React.useState([]);
  const location = useLocation();
  const { state } = location;
  console.log("🚀 ~ ReserveDetails ~ state:", state)

  // State for API payload, initialized with data from location state
  const [apiPayload, setApiPayload] = useState({
    offerState: {},
    paymentType: '',
    property_id: '',
    propertyUserId: '',
    state,
    tripe: {
      message: '',
      phoneNumber: '',
    },
    userId: localStorageData("_id")
  });

  // State for various form inputs and selections
  let [checkIn, setCheckIn] = useState(state?.offerState.checkIn);
  let [checkOut, setCheckOut] = useState(state?.offerState.checkOut);
  let [count, setCount] = useState(state?.offerState.count);
  const [points, setPoints] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedData, setSelectedData] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCvv] = useState('');
  const [existCardNumber, setExistCardNumber] = useState('')
  const [Loading, setLoading] = useState(false);

  const [guests, setGuests] = useState(state.offerState.guest);
  const [rooms, setRooms] = useState(state.offerState.room);

  // Function to handle guest count changes
  const handleGuestChange = (action) => {
    if (action === 'increment' && guests < state?.propertyDetail?.spaceTypeDetail?.guests) {
      setGuests(prevGuests => prevGuests + 1);
    } else if (action === 'decrement' && guests > 1) {
      setGuests(prevGuests => prevGuests - 1);
    }
  };

  // Function to handle room count changes
  const handleRoomChange = (action) => {
    if (action === 'increment' && rooms < state?.propertyDetail?.spaceTypeDetail?.bedrooms) {
      setRooms(prevRooms => prevRooms + 1);
    } else if (action === 'decrement' && rooms > 1) {
      setRooms(prevRooms => prevRooms - 1);
    }
  };

  // Mutation to confirm reservation
  const { mutate, isLoading: LoadingReservationConfirm } = useMutation(
    (reservationData) =>

      userServices.Reservation('/reservation/reservation', reservationData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success('Reservation created successfully');
        console.log("data?.data.data", data?.data.data)
        navigate(`/ConfirmReservation`, {
          state: { offerState: state, propertyDetail: allPost },
          state: apiPayload,
        });
      },
    }
  );

  // Mutation to add card information
  const { mutate: setMutate, isLoading: LoadingCardAdd } = useMutation(
    (reservationData) =>

      userServices.Reservation('/stripe/AddCardInfo', reservationData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        setCardNumber('')
        setExpiryDate('')
        setCardName('')
        setCvv('')
        passMutate()
        setShowAddCard(false)
        toast.success('Your card added successfully');
      },
    }
  );

  // Mutation to fetch user data (e.g., card info)
  const { mutate: passMutate, isLoading: CardLoading } = useMutation(
    (userData) =>

      userServices.userById(`/userAuth/user/${(localStorageData("_id"))}`, userData),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data && data.data && data.data.cardInfo) {
          const { cardNumber } = data?.data?.cardInfo;
          setExistCardNumber(cardNumber)

        }

      },
    }
  );

  // Function to confirm reservation
  const onClickConfirm = () => {
    if (!existCardNumber) {
      return toast.error("please add the card details")
    }
    mutate({ ...apiPayload, state: undefined });

  };

  // Effect to calculate and set points based on count and property detail
  useEffect(() => {
    const sum = state?.offerState?.count * state?.propertyDetail?.points;
    setPoints(sum)
  }, []);
  const handleClick = () => {
    navigate(`/propertydetails/${state.propertyDetail._id}`);

  };
  const handleCheckboxChange = (option) => {
    setSelectedOption((prevOption) => (prevOption === option ? '' : option)); // Toggle the selected option
    if (selectedOption === option) {
      setPaymentType(''); // If the same option is selected, reset the payment type
    } else {
      if (option === 'one') {
        setPaymentType('full');
      } else if (option === 'two') {
        setPaymentType('part');
      }
    }
  };

  const handleAddButtonClick = (field) => {
    setTripe((prevTripe) => ({
      ...prevTripe,
      [field]: !prevTripe[field],
    }));
  };

  // Effect to update API payload whenever state, allPost, paymentType, or tripe changes
  useEffect(() => {

    setApiPayload((prevApiPayload) => ({
      ...prevApiPayload,
      offerState: { ...state.offerState, checkIn: state.offerState.checkIn.$d, checkOut: state.offerState.checkOut.$d },
      property_id: state.propertyDetail._id,
      paymentType: paymentType,
      propertyUserId: state.propertyDetail.userId,
      tripe: { message: tripe.message, phoneNumber: tripe.phoneNumber },
    }));
  }, [state, allPost, paymentType, tripe]);

  //format checkin and checkout date to readable form
  const checkInDate = new Date(state.offerState.checkIn.$d);
  const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });
  const checkOutDate = new Date(state.offerState.checkOut.$d);
  const formattedCheckOutDate = checkOutDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });

  // Function to toggle visibility of select elements
  const handleButtonClick = (buttonName) => {
    setIsSelectVisible((prevVisibility) => ({
      ...prevVisibility,
      [buttonName]: !prevVisibility[buttonName],
    }));
  };

  // Function to handle updating reservation details
  const handleUpdateClick = () => {
    state.offerState.checkIn = checkIn;
    state.offerState.checkOut = checkOut;
    state.offerState.guest = guests;
    state.offerState.room = rooms;
    state.offerState.count = (checkOut - checkIn) / (24 * 60 * 60 * 1000);
    setApiPayload((prevApiPayload) => ({
      ...prevApiPayload,
      offerState: { ...state.offerState, checkIn: checkIn.$d, checkOut: checkOut.$d, guests: guests, rooms: rooms },
    }));
    setIsSelectVisible({
      selectData: false,
      selectGuest: false,
    });
  };

  const handleCheckInChange = (newValue) => {
    setCheckIn(newValue);
  };
  const handleCheckOutChange = (newValue) => {
    setCheckOut(newValue);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value; // Initialize formatted value

    switch (name) {
      case 'cardNumber':
        // Remove non-numeric characters and limit to 16 digits
        formattedValue = value.replace(/\D/g, '').slice(0, 16);
        setCardNumber(formattedValue);
        break;
      case 'cardName':
        if (/^[a-zA-Z\s]*$/.test(value)) { // Check if value contains only letters and spaces
          setCardName(value);
        }
        break;
      case 'expiryDate':
        // Remove non-numeric characters and format as MM/YY
        formattedValue = value.replace(/\D/g, '');
        if (formattedValue.length > 2) {
          formattedValue = `${formattedValue.slice(0, 2)}/${formattedValue.slice(2, 4)}`;
        }
        setExpiryDate(formattedValue);
        break;
        break;
      case 'cvv':
        // Remove non-numeric characters and limit to 3 digits
        formattedValue = value.replace(/\D/g, '').slice(0, 3);
        setCvv(formattedValue);
        break;
      default:
        break;
    }
  };

  const handleAddCard = async () => {
    setLoading(true); // Set loading state to true when API call starts
    try {
      await setMutate({
        cardNumber: cardNumber,
        expiryDate: expiryDate,
        cardName: cardName,
        cvv: cvv,
        userId: localStorageData("_id"),
      });

    } catch (error) {
      // Handle error if needed
      console.error('Error:', error);
    } finally {
      setLoading(false); // Set loading state to false after API call completes (whether success or error)
    }
  }
  useEffect(() => {
    passMutate()
  }, [])

  const [averageRating, setAverageRating] = useState(0);

  //calculating average rating and reviews
  useEffect(() => {
    if (state?.propertyDetail.reviews && state.propertyDetail.reviews.length > 0) {
      const ratings = state.propertyDetail.reviews.map((review) => review.rating);
      const total = ratings.reduce((acc, rating) => acc + rating, 0);
      const average = total / ratings.length;
      setAverageRating(Number(average.toFixed(1)));
    } else {
      // Handle the case where there are no reviews
      setAverageRating(0);
    }
  }, [state?.propertyDetail.reviews]);

  return (
    <>
      <Container maxWidth="xl">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item lg={8} xs={12}>
              <div className="w-full ">
                <div className="flex h-24 item-center">
                  <div className="flex items-center ">

                    <i className="cursor-pointer fas fa-angle-left" onClick={handleClick}></i>
                    <h4 className="ml-6 text-2xl font-semibold">
                      Confirm And Pay
                    </h4>
                  </div>
                </div>
                {/* <div className="p-2 mb-4 border border-[#C1E1C2] rounded-xl">
                  <div className="flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="26"
                      viewBox="0 0 27 28"
                      fill="none"
                    >
                      <path
                        d="M8.96712 24.9704C8.37879 24.9682 7.81495 24.7345 7.39755 24.3199L0.737389 17.6597C0.32446 17.2457 0.0869141 16.674 0.0869141 16.0902C0.0869141 15.5063 0.32446 14.9346 0.737389 14.5206L10.7276 4.53036C11.145 4.11573 11.7089 3.88206 12.2972 3.87988H18.9574C19.1005 3.87988 19.236 3.89653 19.3692 3.92983C20.3793 4.08413 21.1774 5.02099 21.1774 6.09993V12.7601C21.1774 13.344 20.9399 13.9156 20.5269 14.3297L10.5367 24.3199C10.1193 24.7345 9.55546 24.9682 8.96712 24.9704ZM12.2972 4.98991C12.0053 4.98991 11.72 5.10868 11.5124 5.31515L1.52218 15.3054C1.31571 15.513 1.19694 15.7982 1.19694 16.0902C1.19694 16.3821 1.31571 16.6674 1.52218 16.875L8.18233 23.5351C8.59637 23.9492 9.33676 23.9492 9.7508 23.5351L19.741 13.5449C19.9486 13.3373 20.0674 13.052 20.0674 12.7601V6.09993C20.0673 5.83994 19.9749 5.58842 19.8069 5.39006C19.6388 5.1917 19.4058 5.05936 19.1494 5.01655C19.0868 4.99944 19.0222 4.99048 18.9574 4.98991H12.2972Z"
                        fill="#58C0D0"
                      />
                      <path
                        d="M24.4295 27.1908H14.5946C13.9923 27.1922 13.4126 26.9612 12.9762 26.5459C12.7637 26.3419 12.5941 26.0975 12.4774 25.827C12.3607 25.5565 12.2994 25.2654 12.2969 24.9708V21.2189C12.2969 21.0713 12.3557 20.9303 12.4589 20.8259L19.7407 13.5442C19.9483 13.3377 20.0671 13.0524 20.0671 12.7605V6.10033C20.0669 5.84034 19.9746 5.58881 19.8065 5.39046C19.6385 5.1921 19.4055 5.05975 19.1491 5.01695C19.0194 4.99713 18.9009 4.93199 18.8146 4.83309C18.7284 4.7342 18.6799 4.60795 18.678 4.47674C18.676 4.34554 18.7205 4.21787 18.8037 4.1164C18.8869 4.01493 19.0034 3.94622 19.1324 3.92246C19.8539 3.78926 20.6043 4.01348 21.1305 4.52521L26.0457 9.2406C26.4797 9.66019 26.7272 10.2352 26.7272 10.8179V24.9708C26.7247 25.2654 26.6634 25.5565 26.5467 25.827C26.43 26.0975 26.2604 26.3419 26.0479 26.5459C25.6238 26.9566 25.0344 27.1908 24.4295 27.1908ZM13.4069 21.4487V24.9708C13.4069 25.2549 13.5312 25.5391 13.7488 25.7489C13.9775 25.9642 14.2805 26.0831 14.5946 26.0808H24.4295C24.7491 26.0808 25.0566 25.9598 25.2753 25.7489C25.3813 25.6486 25.4662 25.5281 25.5249 25.3944C25.5837 25.2608 25.615 25.1167 25.6172 24.9708V10.8179C25.615 10.672 25.5837 10.5279 25.5249 10.3943C25.4662 10.2607 25.3813 10.1401 25.2753 10.0398L21.1771 6.1081V12.7605C21.1771 13.3444 20.9395 13.916 20.5266 14.3301L13.4069 21.4487Z"
                        fill="#58C0D0"
                      />
                      <path
                        d="M17.292 9.42969C16.374 9.42969 15.627 8.68264 15.627 7.76465C15.627 6.84666 16.374 6.09961 17.292 6.09961C18.21 6.09961 18.957 6.84666 18.957 7.76465C18.957 8.68264 18.21 9.42969 17.292 9.42969ZM17.292 7.20964C17.1448 7.20964 17.0036 7.26811 16.8995 7.3722C16.7955 7.47628 16.737 7.61745 16.737 7.76465C16.737 7.91185 16.7955 8.05302 16.8995 8.1571C17.0036 8.26119 17.1448 8.31966 17.292 8.31966C17.4392 8.31966 17.5804 8.26119 17.6844 8.1571C17.7885 8.05302 17.847 7.91185 17.847 7.76465C17.847 7.61745 17.7885 7.47628 17.6844 7.3722C17.5804 7.26811 17.4392 7.20964 17.292 7.20964Z"
                        fill="#58C0D0"
                      />
                      <path
                        d="M23.9525 8.31999C23.8053 8.31999 23.6641 8.26151 23.56 8.15743C23.4559 8.05334 23.3975 7.91217 23.3975 7.76497V4.4349C23.3975 2.90528 22.152 1.65983 20.6224 1.65983C19.0928 1.65983 17.8473 2.90528 17.8473 4.4349V6.65495C17.8473 6.80215 17.7889 6.94332 17.6848 7.0474C17.5807 7.15149 17.4395 7.20996 17.2923 7.20996C17.1451 7.20996 17.0039 7.15149 16.8999 7.0474C16.7958 6.94332 16.7373 6.80215 16.7373 6.65495V4.4349C16.7373 2.29255 18.48 0.549805 20.6224 0.549805C22.7647 0.549805 24.5075 2.29255 24.5075 4.4349V7.76497C24.5075 7.91217 24.449 8.05334 24.3449 8.15743C24.2408 8.26151 24.0997 8.31999 23.9525 8.31999Z"
                        fill="#58C0D0"
                      />
                    </svg>
                    <h4 className="ml-1 text-lg font-semibold">Good price</h4>
                  </div>
                  <p className="text-sm">
                    Your dates are $11 less than the avg. nightly rate over the
                    last 3 months.
                  </p>
                </div> */}
                <div className="mt-4">
                  <h4 className="mb-2 ml-1 text-lg font-semibold">
                    Stay Duration
                  </h4>
                  <div className="flex items-center justify-between ">
                    <h4 className="ml-1 font-semibold text-md">Dates</h4>
                    {isSelectVisible.selectData ? (
                      <button onClick={handleUpdateClick} className="text-color-yellow">
                        Update
                      </button>
                    ) : (
                      <ButtonWithoutBackground
                        onClick={() => handleButtonClick('selectData')}
                        text="Edit"
                      />
                    )}

                  </div>

                  {isSelectVisible.selectData ? 'Selected Data' :
                    <p className="ml-1 text-sm">
                      {formattedCheckInDate} - {formattedCheckOutDate}
                    </p>
                  }
                  {isSelectVisible.selectData &&
                    <div className="flex w-full border-2 border-gray-400 rounded-t">
                      <div className="p-2 border-r-2 border-gray-400">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Basic example"
                            value={checkIn.$d}
                            dateFormat="LLL"
                            onChange={handleCheckInChange}
                            minDate={currentDate.toDate()}
                            shouldDisableDate={(date) => state.propertyDetail.bookDates.includes(date.toISOString())}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                              params }) => (
                              <div className="flex items-center ">
                                <input
                                  className="w-24 text-black outline-0"
                                  ref={inputRef}
                                  {...inputProps}
                                  placeholder="Check-in"
                                  {...params}
                                />
                                {InputProps?.endAdornment}
                              </div>
                            )}
                          />
                        </LocalizationProvider>

                      </div>

                      <div className="p-2">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="Basic example"
                            value={checkOut.$d}
                            onChange={handleCheckOutChange}
                            minDate={currentDate.toDate()}
                            shouldDisableDate={(date) => state.propertyDetail.bookDates.includes(date.toISOString())}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                              params,
                            }) => (
                              <div className="flex items-center">
                                <input
                                  className="w-24 text-black outline-0"
                                  ref={inputRef}
                                  {...inputProps}
                                  placeholder="Check-out"
                                  {...params}
                                />
                                {InputProps?.endAdornment}
                              </div>
                            )}
                          />
                        </LocalizationProvider>
                      </div>
                    </div>
                  }


                </div>
                <div className="mt-2">
                  <div className="flex items-center justify-between">
                    <h4 className="ml-1 font-semibold text-md">Guests & Rooms</h4>
                    {isSelectVisible.selectGuest ? (
                      <button onClick={handleUpdateClick} className="text-color-yellow">
                        Update
                      </button>
                    ) : (
                      <ButtonWithoutBackground
                        onClick={() => handleButtonClick('selectGuest')}
                        text="Edit"
                      />
                    )}
                  </div>

                  <p className="ml-1 text-sm">
                    {isSelectVisible.selectGuest ? 'Selected option' : `${state?.offerState?.guest} guests & ${state?.offerState?.room} rooms`}
                  </p>
                  <div className="w-full my-3">
                    {isSelectVisible.selectGuest && (

                      <GuestAndRoomSelector
                        handleGuestChange={handleGuestChange}
                        handleRoomChange={handleRoomChange}
                        guests={guests}
                        rooms={rooms}
                      />)}
                  </div>
                </div>

                <hr className="mt-6 border-[#C1E1C2] " />
                <div className="flex flex-col  justify-around">
                  <h4 className="mt-2 mb-2 text-lg font-semibold ">
                    Choose how to pay
                  </h4>
                  <div>
                    {/* Option One */}
                    <div className={`border border-[#C1E1C2] rounded-xl flex justify-between items-center mb-4 p-2 ${selectedOption === 'one' ? 'bg-gray-200' : ''}`}>
                      <div>
                        <div className="flex items-center">
                          <h4 className="font-semibold text-md">Pay in full</h4>
                        </div>
                        <p className="text-sm">
                          Pay the Service fee <span className="font-semibold">( ${state?.offerState?.serviceFee} ) </span>now and you're all set.
                        </p>
                      </div>
                      <div className="flex items-center justify-center">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="h-5 w-5"
                          checked={selectedOption === 'one'}
                          onChange={() => handleCheckboxChange('one')}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                <hr className="my-6 border-[#C1E1C2] " />

                <div className="">
                  <h4 className="mt-2 mb-2 text-lg font-semibold ">
                    Payment
                  </h4>
                  <div className="w-[40%]">
                    {existCardNumber && <PaymentCard name="VISA Master Card" icon={visaIcon} number={existCardNumber} verification="verified" />}
                  </div>
                  <div className="mt-4">
                    {!showAddCard &&
                      <i class="fa-solid fa-plus" style={{ cursor: 'pointer' }} onClick={() => setShowAddCard(true)}>add new card</i>}
                  </div>
                  {showAddCard && <div className="p-2 border rounded-md border-[#C1E1C2]">
                    <div className="flex">
                      <div className="w-[50%] ">
                        <h4 className="mt-2 mb-2 text-lg font-semibold ">
                          Credit/Debit Card
                        </h4>
                      </div>
                      <div className="w-[50%] flex justify-end">

                        <i class="fa-solid fa-x" onClick={() => setShowAddCard(false)} ></i>
                      </div>
                    </div>
                    <hr className="my-2 border-[#C1E1C2] " />
                    <div className="flex flex-wrap gap-y-2 items-center">
                      <img
                        src={require("assets/img/visa.png")}
                        alt=""
                        className="w-16 mx-2"
                      />
                      <div className="border border-[#C1E1C2] rounded-md">
                        <img
                          src={require("assets/img/paypal.png")}
                          alt=""
                          className="py-1 mx-3"
                        />
                      </div>
                      <div className="border border-[#C1E1C2] rounded-md mx-2">
                        <img
                          src={require("assets/img/pay.png")}
                          alt=""
                          className="py-1 mx-2"
                        />
                      </div>

                      <img
                        src={require("assets/img/point.png")}
                        alt=""
                        className="h-10 rounded "
                      />
                      <div className="py-2">
                        <button className="px-2 mx-2 text-sm border rounded-md  h-9 border-[#C1E1C2]">
                          {" "}
                          Other
                        </button>
                      </div>
                    </div>
                    <div>
                      <img
                        src={require("assets/img/all.png")}
                        alt=""
                        className="p-2 "
                      />
                    </div>
                    <div className="flex items-center gap-2 mt-2">
                      <div className="w-1/2">
                        <label htmlFor="">Card Number*</label>
                        <input
                          type="text"
                          name="cardNumber"
                          className="input-styl placeholder:text-color-grey"
                          placeholder="Card Number"
                          value={cardNumber}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="w-1/2">
                        <label htmlFor="">Name on Card*</label>
                        <input
                          type="text"
                          name="cardName"
                          value={cardName}
                          onChange={handleInputChange}
                          className="input-styl placeholder:text-color-grey"
                          placeholder="Name on card"
                        />
                      </div>
                    </div>
                    <div className="flex items-center gap-2 mt-2">
                      <div className="w-1/2">
                        <label htmlFor="" className="text-[15px] md:text-[17px]">Expiration Date*</label>
                        <input
                          type="text"
                          name="expiryDate"
                          className="input-styl placeholder:text-color-grey"
                          placeholder="MM/YY"
                          value={expiryDate}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="w-1/2">
                        <label htmlFor="">CVC*</label>
                        <input
                          type="text"
                          className="input-styl placeholder:text-color-grey"
                          placeholder="CVC"
                          name="cvv"
                          value={cvv}
                          onChange={handleInputChange}

                        />
                      </div>
                    </div>
                    <button className="btn-styl-blue" onClick={handleAddCard} disabled={LoadingCardAdd}>
                      {LoadingCardAdd ? 'Adding Card...' : 'Add Card'}</button>
                    <div className="flex p-2 rounded-md bg-color-blue/25 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="22"
                        viewBox="0 0 19 22"
                        fill="none"
                      >
                        <path
                          d="M17.7793 6.25855C17.7692 6.03223 17.7627 5.80576 17.7596 5.57923C17.747 4.72827 17.0775 4.03382 16.2278 3.98636C13.629 3.84118 11.5966 2.97675 9.87985 1.30346C9.59258 1.04013 9.16171 1.04013 8.87444 1.30346C7.15771 2.97675 5.12529 3.84118 2.5265 3.98636C1.67679 4.03382 1.00732 4.72827 0.994682 5.57919C0.99162 5.80573 0.985065 6.03221 0.975023 6.25855C0.879515 11.2735 0.736337 18.1465 9.07763 21.0551C9.11757 21.069 9.15843 21.0801 9.19991 21.0884L9.20032 21.0885C9.31705 21.1118 9.43724 21.1118 9.55397 21.0885C9.59561 21.0802 9.63692 21.0689 9.67703 21.0549C17.9941 18.1464 17.8748 11.2972 17.7793 6.25855Z"
                          stroke="black"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M14.0487 11.106C14.0487 13.6862 11.9571 15.7778 9.37689 15.7778C6.79673 15.7778 4.70508 13.6862 4.70508 11.106C4.70508 8.52577 6.79673 6.43412 9.37689 6.43412C11.9571 6.43412 14.0487 8.52577 14.0487 11.106Z"
                          stroke="black"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M11.3398 9.90287L8.43945 12.8032"
                          stroke="black"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                        <path
                          d="M7.33105 11.6949L8.43941 12.8032"
                          stroke="black"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                        />
                      </svg>
                      <p className="ml-2 text-sm">
                        This transaction is protected with a secure and
                        encrypted payment system by Bnbyoud
                      </p>
                    </div>
                  </div>}
                  <hr className="my-6 border-[#C1E1C2] " />
                  <div>
                    <h4 className="mt-2 mb-2 text-lg font-semibold ">
                      Required for your trip
                    </h4>
                    <div className="flex items-center justify-between gap-5">
                      <div>
                        <p className="text-md">Message the Host</p>
                        <p className="text-xs mb-2">
                          Let the Host know why you're traveling and when you'll check in.
                        </p>
                        {tripe.showTextarea && ( // Render textarea only when showTextarea is true
                          <textarea
                            value={tripe.message}
                            onChange={(e) => setTripe({ ...tripe, message: e.target.value })}
                            placeholder="Type something..."
                            className="w-full p-2 border border-color-grey rounded-xl"
                          />
                        )}
                      </div>
                      <button
                        className="px-4 py-1 text-sm border rounded-full text-color-yellow border-color-yellow"
                        onClick={() => handleAddButtonClick('showTextarea')}
                      >
                        {tripe.showTextarea ? 'CLOSE' : 'ADD'}
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-3">
                    <div>
                      <p className="text-md">Phone number</p>
                      <p className="text-xs mb-2">
                        Add and confirm your phone number to get trip updates.
                      </p>
                      {tripe.showPhoneField && (
                        <input
                          type="tel"
                          value={tripe.phoneNumber}
                          onChange={(e) => setTripe({ ...tripe, phoneNumber: e.target.value })}
                          placeholder="Enter phone number..."
                          className="w-full p-2 border border-color-grey rounded-xl"
                        />
                      )}
                    </div>
                    <button
                      className="px-4 py-1 text-sm border rounded-full text-color-yellow border-color-yellow"
                      onClick={() => handleAddButtonClick('showPhoneField')}
                    >
                      {tripe.showPhoneField ? 'CLOSE' : 'ADD'}
                    </button>
                  </div>

                  <hr className="my-6 border-[#C1E1C2] " />
                  <div>
                    <h4 className="mt-2 mb-2 text-lg font-semibold ">
                      Cancellation policy
                    </h4>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-xs">
                          Free cancellation for 48 hours. Cancel before Oct 27
                          for a partial refund.
                        </p>
                        <Link to='/pricing' className="text-sm underline">Read More</Link>
                      </div>
                    </div>
                  </div>
                  <hr className="my-6 border-[#C1E1C2] " />
                  <div className="my-4">
                    <p className="text-xs">
                      By selecting the button below, I agree to the Host's House
                      Rules, Ground rules for guests, Bnbyond's Rebooking and
                      Refund Policy, Pay Less Upfront Terms, and that Bnbyond
                      can charge my payment method if I’m responsible for
                      damage.
                    </p>
                    <button className="btn-styl-blue" onClick={onClickConfirm} disabled={LoadingReservationConfirm}>
                      {LoadingReservationConfirm ? "Reserving..." : "reserve"}
                    </button>
                  </div>
                </div>

              </div>
            </Grid>
            <Grid item lg={4} xs={12}>
              {" "}

              <div className="flex mt-2 reserveDetailWrapper">
                {/* Image Areas */}
                <div className="h-full mt-20 ml-4 border border-[#C1E1C2] height-line rounded-xl">
                  <div className="reserveImage ">
                    {state.propertyDetail.pics ? <img
                      src={state.propertyDetail.pics[0]}
                      className="object-cover w-full h-52 rounded-t-xl "
                      alt=""
                    />
                      : <img
                        src={require("assets/img/background.png")}
                        className="object-cover w-full h-52 rounded-t-xl "
                        alt=""
                      />}

                  </div>
                  <div className="items-center h-full ">
                    <div className="flex flex-col items-start justify-between propertyName">
                      <p className="flex items-center pl-3 pt-3">
                        {averageRating > 0 ?
                          <div className="flex items-center gap-2">
                            <Rating
                              initialRating={averageRating}
                              readonly
                              emptySymbol={<FaRegStar color="#d3d3d3" />}
                              halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                              fullSymbol={<FaStar color="#ffd700" />}
                              fractions={2}
                            />
                            <span>({averageRating})</span>
                          </div>
                          :
                          <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                        }
                      </p>
                      <p className="my-1 ml-3 text-2xl font-semibold">
                        {state.propertyDetail.title}
                      </p>
                    </div>
                    <div className="flex items-center justify-between mt-8 font-semibold propertyName ">
                      <p className="ml-3 font-md">{state.offerState.pointsPerNight} x {state.offerState.count ? state.offerState.count : 0} nights</p>
                      <p className="flex items-center mr-3"> {state.offerState.total ? state.offerState.total : 0} points</p>
                    </div>
                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md">Cleaning & other fees</p>
                      <p className="flex items-center mr-3"> {state.offerState.fees}</p>
                    </div>
                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md">Bnbyond service fee-3%</p>
                      <p className="flex items-center mr-3"> ${state.offerState.serviceFee}</p>
                    </div>
                    <hr className="mt-6 border-[#C1E1C2] " />
                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md">Total Points</p>
                      <p className="flex items-center mr-3"> {state.offerState.points} points</p>
                    </div>
                    <div className="flex items-center justify-between mt-2 font-semibold propertyName">
                      <p className="ml-3 font-md ">
                        Service Fee
                      </p>
                      <p className="flex items-center mr-3 ">
                        {" "}
                        ${state.offerState.serviceFee}
                      </p>
                    </div>

                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <br />
    </>
  );
}

export default ReserveDetails;
