import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Image1 from "../../assets/img/image1.png";
import Image2 from "../../assets/img/image2.png";
import Image3 from "../../assets/img/image3.png";
import Image4 from "../../assets/img/image4.png";
import Image5 from "../../assets/img/image5.png";
import Container from "@mui/material/Container";
import LandingHeader from "components/Headers/LandingHeader";
import HomeCard from "components/Cards/HomeCard";
import userServices from "services/httpService/userAuth/userServices";
import Navbar from "components/Navbars/AuthNavbar";
import Footerbn from "pages/Footer/Footerbn";
import { localStorageData, } from "services/auth/localStorageData";
import { CircularProgress, Divider } from "@mui/material";
import OurBlogs from "components/Blogs/OurBlogs";


export default function LandingPage() {
  const [allPost, setallPost] = React.useState([]);
  const [favoriteRender, setFavoriteRender] = useState(false);
  const [displayedCards, setDisplayedCards] = useState(8);
  const [isLoading, setIsLoading] = useState(false);

  const showMoreCards = () => {
    setDisplayedCards((prev) => prev + 8);
  };

  const images = [Image1, Image2, Image3, Image4, Image5];

  const names = [
    "Chicago",
    "New York",
    "San Diego",
    "Seattle",
    "Washington, DC",
  ];

  // Fetches all available properties from the server
  const getproperty = async () => {
    try {
      let res = await userServices.commonGetService(`/property/getAllPropertyAvailable`);
      setIsLoading(false)
      if (res.data && Array.isArray(res.data.data)) {
        const activeProperties = res.data.data.filter(property => property.status === "Active");
        setallPost(activeProperties);
      } else {
        console.error("Error: Unable to fetch property data");
      }
    } catch (error) {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getproperty();
  }, [favoriteRender]);

  // useEffect(() => {
  //   if (allPost.length > 0) {
  //     setIsLoading(false);
  //   }
  // }, [allPost]);

  //slider settings and responsivness
  const settings = {
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Navbar />
      <main style={{ overflowX: 'hidden' }}>
        <LandingHeader />
        <section className="block py-10 lg:pt-0 lg:mx-12 md:mx-14 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg" className="">
            <div style={{ marginTop: "-30px" }}>
              <Slider {...settings}>
                {images.map((imageUrl, index) => (
                  <div key={index}>
                    <div
                      className="md:w-[290px] w-full h-[369px] bg-center bg-cover relative overflow-hidden"
                      style={{
                        backgroundImage: `url(${imageUrl})`,
                      }}
                    >
                      <div className="overlay absolute inset-0 bg-[#58C0D0] opacity-0 transition-opacity hover:opacity-90"></div>
                      <span className="text-[#FFFFFF] text-base p-3 absolute bottom-0 z-30 ">
                        {names[index]}
                      </span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Container>
        </section>

        <section className="block py-10 lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <div className="rounded-xl">
              <div className="text-center">
                <h4 className="text-[#000000] text-3xl font-medium">
                  Explore Homes Nearby
                </h4>
              </div>

              {isLoading ? (
                <div className="flex justify-center items-center h-80">
                  <CircularProgress />
                </div>
              ) : (
                <div>
                  <div className="flex flex-wrap w-full gap-5 sm:justify-center">
                    {allPost.slice(0, displayedCards).map((item) => {
                      const favourite =
                        item?.favouriteList &&
                        item.favouriteList.find(
                          (user) => user.userId === localStorageData("_id")
                        );

                      return (
                        <div
                          key={item._id}
                          className="mx-1 my-1 xs:w-full sm:w-[265px] md:w-[265px] w-full"
                        >
                          <HomeCard
                            data={item}
                            favourite={favourite}
                            favoriteRender={favoriteRender}
                            setFavoriteRender={setFavoriteRender}
                          />
                        </div>
                      );
                    })}
                  </div>
                  {displayedCards < allPost.length && (
                    <div className="mt-6 text-center">
                      <button
                        onClick={showMoreCards}
                        className="text-base font-medium border border-[#E8AD21] rounded-full w-full md:w-[150px] h-[50px] text-[#E8AD21] bg-[#FFFFFF]"
                      >
                        See more
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Container>
        </section>

        <section className="">
          <section className="bg-[#58C0D0]">
            <section className="block py-10 mt-40 lg:pt-0">
              <Container maxWidth="lg">
                <div className="relative flex center-styl landing-footer ">
                  <div
                    className="absolute w-full mt-[-300px] h-full bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/luxury.png") + ")",
                      // marginTop:'-40px'
                    }}
                  >
                    <div
                      id="blackOverlay"
                      className="absolute top-0 left-0 z-0 w-full h-full bg-black opacity-50"
                    ></div>
                    <div className="relative z-10 px-4 mt-20 md:pl-10">
                      <div className="md:text-4xl text-[20px] font-bold text-white w-full md:w-[618px] h-[164px]">
                        <p>Host other BnByond Members.</p><p>
                          <span className="text-[#E8AD21] underline">
                            Get Points.
                          </span>
                          Use Them to</p>
                        <p>Travel Freely</p>
                      </div>
                      <div className="mt-4">
                        <button className="text-base font-medium rounded-full w-full md:w-[320.5px] h-[50px] text-[#E8AD21] bg-[#FFFFFF]">
                          <Link to="/propertylisting">Vacation Rental Community Exchange.</Link>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-full gap-4 xs:flex-wrap sm:justify-center xs:justify-center md:justify-between sm:flex-wrap lg:flex-row">
                  <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/freeValue.png")} />
                      </div>
                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Far More Value
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Get the absolute most from your vacation rental property
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/accomodation.png")} />
                      </div>
                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Enjoy Endless Accommodations
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Enjoy accommodations everywhere without paying a dime
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/community-icon.png")} />
                      </div>

                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Amazing Community
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          Amazing community of vacation rental owners/like-minded travellers
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
                    <div className="flex flex-col items-center justify-center">
                      <div className="w-[60px] h-[60px]">
                        <img src={require("assets/img/guests.png")} />
                      </div>
                      <div className="text-center">
                        <h4 className="text-[#000000] pt-3 text-md font-normal">
                          Quality Guests
                        </h4>
                        <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                          The most responsible guests are other vacation rental owners
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full  relative max-w-[1200px] ml-auto mr-auto px-6 md:px-[120px] mt-10 z-40 hidden md:flex flex-wrap justify-between">
                  <div
                    className="w-[20px] rounded-full border border-[#FFFFFF] bg-[#58C0D0] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <div
                    className="w-[20px] rounded-full bg-[#C1E1C2] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <div
                    className="w-[20px] rounded-full bg-[#E8AD21] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <div
                    className="w-[20px] rounded-full bg-[#C0392D] h-[20px]"
                    style={{ zIndex: "40" }}
                  />
                  <Divider className="w-full absolute top-2.5 left-0 bg-[#EDEDED] max-w-[1140px] ml-auto mr-auto mt-[-10px] z-0" />
                </div>
              </Container>
            </section>
          </section>
        </section>

        <section className="block py-10 lg:pt-0 lg:mx-12 sm:mx-0 xs:mx-0">
          <Container maxWidth="lg">
            <div className="w-full mt-8">
              <div className="text-center">
                <h4 className="text-[#000000] text-3xl font-medium">
                  Trending Destinations
                </h4>
              </div>
              <div className="md:flex w-full h-full md:h-[600px] mt-5 ">
                <div className="relative w-full">
                  <p className="absolute bottom-0 z-40 text-white left-2 text-[30px]">
                    Key West
                  </p>

                  <img
                    className="h-[100%] w-full rounded-l-md object-cover"
                    src={require("assets/img/pic01.jpg")}
                  />
                </div>
                <div className="w-full md:flex md:flex-col">
                  <div className="w-full md:flex ">
                    <div className="relative w-full group">
                      <p className="absolute bottom-0 z-40 text-white left-2 text-[30px]">
                        Orlando
                      </p>
                      <img
                        className="h-[300px] w-full object-cover"
                        src={require("assets/img/pic02.jpg")}
                      />
                      <div className="absolute inset-0 bg-[#58C0D0] opacity-0 group-hover:opacity-90 transition-opacity"></div>
                    </div>
                    <div className="relative w-full group">
                      <p className="absolute bottom-0 z-40 text-white left-2 text-[30px]">
                        San Francisco
                      </p>

                      <img
                        className="h-[300px] rounded-tr-md w-full object-cover"
                        src={require("assets/img/pic04.jpg")}
                      />
                      <div className="absolute inset-0 bg-[#58C0D0] opacity-0 group-hover:opacity-90 transition-opacity"></div>
                    </div>
                  </div>
                  <div className="md:flex ">
                    <div className="relative w-full group">
                      <p className="absolute bottom-0 z-40 text-white left-2 text-[30px]">
                        Las Vegas
                      </p>

                      <img
                        className="h-[300px] w-full object-cover"
                        src={require("assets/img/pic03.jpg")}
                      />
                      <div className="absolute inset-0 bg-[#58C0D0] opacity-0 group-hover:opacity-90 transition-opacity"></div>
                    </div>
                    <div className="relative w-full group">
                      <p className="absolute bottom-0 z-40 text-white left-2 text-[30px]">
                        New York
                      </p>

                      <img
                        className="h-[300px] w-full rounded-br-md object-cover"
                        src={require("assets/img/pic05.jpg")}
                      />
                      <div className="absolute inset-0 bg-[#58C0D0] opacity-0 group-hover:opacity-90 transition-opacity"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section>
          <OurBlogs />
        </section>
      </main>
      <div className="flex justify-center  w-full">

        <section className=" flex relative z-50 md:top-20  w-[94%]  lg:mb-10 ">
          <div className="">
            <img src={require("../../assets/img/icon.png")} className="absolute left-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] z-[-10]  " />
          </div>
          <Container sx={{
            width: '100%',        // xl:w-full
            maxWidth: '100%',     // xl:w-full
            '@media (min-width: 1024px)': {
              width: '90%',       // lg:w-[90%]
              maxWidth: '90%',    // lg:w-[90%]
            },
          }} style={{ paddingLeft: "6px", paddingRight: "6px" }}>
            <div
              className="bg-[#AF3227]/95 md:h-[300px] h-full lg:w-[95%] xl:w-[96%] mx-auto rounded-2xl "
              style={{
                backgroundImage:
                  "url(" + require("assets/img/badgebg.png") + ")",
                backgroundRepeat: "no-repeat",
              }}
            >

              <Container>

                <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">

                  <div className="lg:pl-10">
                    <div className="md:w-[445px] flex items-center justify-center  w-full">
                      <p className="text-white text-[25px] text-center pt-3 md:pt-0 md:text-[36px] font-bold">
                        Get travel tips, offers and inspiration from BnByond
                      </p>
                    </div>
                    <div className="relative pt-4">
                      <input
                        type="text" placeholder="Email address"
                        className="md:w-[400px] lg:w-[495px] bg-transparent  pl-5 border text-white  placeholder-white border-white w-full h-[50px] rounded-2xl"
                      />
                      <button className="text-[#C0392D] absolute right-0 text-base font-medium bg-white rounded-2xl md:w-[150px] w-full h-[50px]">
                        Subscribe
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("assets/img/downrrow.png")} />
                  </div>
                  <div className="relative">
                    <img src={require("assets/img/squad2.png")} />
                    <img
                      src={require("assets/img/squad.png")}
                      className="absolute z-40 top-4"
                    />
                  </div>
                </div>
              </Container>
            </div>

          </Container>

          <div className="">
            <img src={require("../../assets/img/icon.png")} className="absolute right-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] transform scale-x-[-1] z-[-10]   " />
          </div>

        </section>

      </div>
      <Footerbn />
    </>
  );
}