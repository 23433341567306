import React, { useEffect, useRef, useState } from 'react'
import { ButtonFilled } from 'common/buttons/buttonFilled'
import ReferralPic from "../../../assets/img/ReferralPic.png";
import backgroundImage from "../../../assets/img/backgroundImage.png";
import referalpoint from "../../../assets/img/referalpoint.png";
import PintsPic from "../../../assets/img/PintsPic.png";
import CirclePic from "../../../assets/img/CirclePic.png";
import ReferralsIcon from "../../../assets/img/ReferralsIcon.png";
import Modal from "../../../components/Modal/Modal"
import { TiSocialTwitter } from "react-icons/ti";
import { FaFacebookF } from "react-icons/fa";
import { FaRedditAlien } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { LuCopy } from "react-icons/lu";
import StackedBarLineChart from '../LineChart/LineChart';
import { GraphComponent } from 'common';
import userServices from 'services/httpService/userAuth/userServices';
import { localStorageData } from "services/auth/localStorageData";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import ErrorService from 'services/formatError/ErrorService';



const Referrals = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userData, setUserData] = useState('')
  const selectReferrelType = useRef(null)
  let userRoles = localStorageData("roles");
  let isInfluencer = userRoles?.includes("Influencer");
  const openModal = () => {
    setIsModalOpen(true);

  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const data = {
    title: "Teachers",
    color: {
      textColor: "text-blue-900",
      background: 'bg-white',
      boxShadow: "0px 10px 20px 0px #e0c6ff",
    },
    series: [
      {
        name: "Total Earnings",
        data: [1000, 2000, 1008, 2041, 1102, 2109, 1280, 4000, , , , ,],
      },
    ],
    options: {
      chart: {
        type: 'area',
        height: '180px'
      },
      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },
      fill: {
        type: "gradient",
        colors: ["blue"],
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        colors: ["blue"],
      },
      grid: {
        show: true,
        borderColor: '#e0e0e0',
        xaxis: {
          lines: {
            show: true,
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        },
      },
      yaxis: {
        show: false
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'June',
          'July',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
      }
    }
  }

  // Handles sharing referral link via Twitter DM
  const handleTwitter = (refCode) => {
    const shareUrl = 'https://www.bnbyond.com/auth/signup';
    const referralLink = refCode ? `${shareUrl}?referralCode=${refCode}` : shareUrl;
    const message = `Check out this link: ${referralLink}`;

    const twitterDMUrl = `https://twitter.com/messages/compose?text=${encodeURIComponent(message)}`;
    window.open(twitterDMUrl, '_blank');
  };

  // Handles sharing referral link via WhatsApp
  const handleWhatsapp = (refCode) => {
    // setMutate({ userId: localStorageData("_id") });

    if (refCode) {
      const shareUrl = 'https://www.bnbyond.com/auth/signup';
      const referralLink = `${shareUrl}?referralCode=${refCode}`;

      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(referralLink)}`;

      window.open(whatsappUrl, '_blank');
    }
  };

  // Handles sharing referral link via Facebook Messenger
  const handleFacebookMessenger = (refCode) => {
    const shareUrl = 'http://localhost:3000/Referrals';
    const message = 'Check out this link!';
    window.open(`https://www.facebook.com/messages/new?message=${encodeURIComponent(message)}`, '_blank');
  };

  // Handles sharing referral link via Reddit DM
  const handleReddit = (refCode) => {
    const shareUrl = 'https://www.bnbyond.com/auth/signup';
    const referralLink = refCode ? `${shareUrl}?referralCode=${refCode}` : shareUrl;

    window.open(`https://www.reddit.com/message/compose?to=&subject=Check%20out%20this%20link!&message=${encodeURIComponent(referralLink)}`, '_blank');
  };

  // Handles sharing referral link via Email
  const handleEmail = (refCode) => {
    if (refCode) {
      const shareUrl = 'https://www.bnbyond.com/auth/signup';
      const subject = 'Invitation Link';
      const body = `${shareUrl}?referralCode=${refCode}`;
      // console.log("body", body)
      const emailUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = emailUrl;
    }
  };

  // Generates the referral code and handles the selected sharing method
  const { mutate: setMutate, isLoading: loading } = useMutation(
    (token) =>
      userServices.getReferralCode("userAuth/generateReferralCode", token),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        const referralCode = data?.data?.referralCode;
        // setHandleRaferralCode(referralCode);
        console.log("🚀 ~ Referrals ~ handleReferrelType:", selectReferrelType)
        switch (selectReferrelType.current) {
          case 'twitter':
            handleTwitter(referralCode)
            break;
          case 'whatsapp':
            handleWhatsapp(referralCode)
            break;
          case 'facebook':
            handleFacebookMessenger(referralCode)
            break;
          case 'reddit':
            handleReddit(referralCode)
            break;
          case 'email':
            handleEmail(referralCode)
            break;
          default:
            return

        }

      },
    }
  );

  // Initiates the process of sending a referral by setting the referral type and calling the mutation
  const handleSendReferrel = (refferelType) => {
    selectReferrelType.current = refferelType
    // setHandleReferrelType(refferelType)
    setMutate({ userId: localStorageData("_id") })
  }

  // Fetches user data when the component mounts
  const { mutate, isLoading } = useMutation(
    (userData) =>
      userServices.userById(
        `/userAuth/user/${localStorageData("_id")}`,
        userData
      ),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data?.data) {
          setUserData(data.data)
        }
        console.log("🚀 ~ Referrals ~ data.data:", data.data)
      },
    }
  );
  useEffect(() => {
    mutate();
  }, []);

  console.log("🚀 ~ Referrals ~ setUserData:", userData)

  return (
    <>
      <div className='flex flex-col gap-8 md:flex-row md:my-16 md:mx-20 md:gap-20'>
        <div className='md:w-[50%] pl-5 pr-5 md:pl-10 md:pr-5 mt-14'>
          <div className='flex items-center gap-2'>
            <h2 className='text-2xl font-semibold leading-tight text-yellow-500 md:text-4xl font-avenir'>
              Refer a Friend
            </h2>
            <img src={ReferralsIcon} className='w-7 h-7' alt='Referrals Icon' />
          </div>
          <p className='mt-2 text-xl font-extrabold leading-7 text-color-gray md:text-2xl' style={{ fontFamily: 'Avenir' }}>
            Increase your user signups with an all-time classic referral program.
          </p>

          <div className='pb-5 w-52 mt-7'>
            {isInfluencer ?
              <ButtonFilled onClick={openModal} text='Invite your friends' /> : null
            }
          </div>
        </div>

        <div className='relative md:w-[50%]'>
          <img src={ReferralPic} alt='Referral' />
          <img src={CirclePic} className='absolute top-0 -z-10' alt='Circle' />
          <img src={PintsPic} className='absolute bottom-0 -left-10' alt='Points' />
        </div>
      </div>
      <div className='w-full pt-2 border-red-600 bg-color-darkgray lg:h-72'>
        <p className='text-center mt-5 text-[36px] leading-10 font-medium font-avenir'>
          Here's how to spread the cheer
        </p>
        <div className='flex justify-center w-full border-red-600'>
          <div className='flex flex-col md:flex-row md:justify-between md:items-center md:mx-4 mt-10 w-[82%]'>
            {/* Steps 1, 2, and 3 */}
            {[1, 2, 3].map((step) => (
              <div key={step} className='flex w-full mt-6 mb-8 border-red-500 md:mx-2 md:mb-0'>
                <div className='w-[50px] h-[50px] md:w-[50px] md:h-[50px] border-solid border-white border-2 rounded-full flex justify-center items-center bg-color-blue text-white'>

                  <p>{step}</p>
                </div>

                <div className='flex flex-col items-start ml-4 w-[75%] border-green-500'>
                  <div className='text-lg md:text-[24px]  font-medium text-black font-avenir normal-italic'>
                    {step === 1
                      ? 'You invite'
                      : step === 2
                        ? 'They join'
                        : 'You both earn'}
                  </div>
                  <div className='mt-1 text-sm leading-7  text-color-grayText md:text-lg'>
                    {/* Adjusted text for responsiveness */}
                    {step === 1
                      ? "Send an invitation to a friend who doesn't know bnbyond."
                      : step === 2
                        ? 'Have them sign up and link their bank account and start booking.'
                        : "When they spend at least $49 to subscribe, we'll send you both points."}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>




      </div>
      <div className='flex items-center justify-center mt-8'>
        <div className='w-full md:w-4/5 lg:gap-96 flex flex-col md:flex-row items-center lg:h-40 bg-cover bg-center xs:h-[300px] xs:flex xs:justify-center xs:items-center xs:gap-[20px] md:h-40' style={{ backgroundImage: `url(${backgroundImage})` }}>
          <div className='flex gap-4 pl-5'>
            <img
              className="w-[100px] h-[100px] rounded-full object-cover"
              src={userData?.pic}
              alt="Create Profile"
            />
            <div className='flex flex-col justify-center text-white'>
              <h3 className='text-lg font-bold'>{`${userData?.fname}  ${userData?.lname}`}</h3>
              <p className='text-sm font-normal'>Booking with your earning points</p>
            </div>
          </div>
          <div className='flex gap-6'>
            <img
              className=" object-cover"
              src={referalpoint}
              alt="Create Profile"
            />
            <h2 className='flex items-center justify-center text-2xl font-semibold text-white font-avenir'>
              {userData?.points} Points
            </h2>

          </div>
        </div>
      </div>
      <div className='flex items-center justify-center w-full my-6 border-blue-800'>
        <div className='items-center justify-center w-full gap-8 md:w-4/5 lg:flex mt-9 border-1 border-color-green'>

          <div className='w-full md:w-[35%] h-48 border border-[#C1E1C2] rounded-2xl'>
            {/* <StackedBarLineChart/> */}
            <GraphComponent params={data} />
          </div>

          <div className='w-full md:w-[65%] xs:h-auto lg:h-48 border border-[#C1E1C2] rounded-2xl'>
            <div className="flex  flex-col md:flex-row md:justify-between md:items-center w-full md:w-[80%] pl-4 md:pl-[20px] mt-4 md:mt-[10px]">
              <div className="text-center md:text-left md:mr-4 w-full md:w-[60%]">
                <h2 className="text-[36px] font-bold mb-1">{userData?.points}</h2>
                <p className="text-[#646464] text-base font-normal font-lato">Total points</p>

              </div>
              <div className="text-center md:text-left md:ml-4 w-full md:w-[20%]">
                <h2 className="mb-1 text-2xl font-medium">{userData?.pendingPoints}</h2>
                <p className="text-[#646464] text-base font-normal font-lato">Pending Points</p>
              </div>
            </div>

            <div className="flex flex-col md:flex-row md:justify-between md:items-center w-full md:w-[80%] pl-4 md:pl-[20px] mt-4 md:mt-[10px]">
              <div className="text-center md:text-left md:mr-4 w-full md:w-[70%]">
                <p className="text-[#646464] text-base font-normal font-lato">Your total points reflect the sum of your efforts and experiences, a tangible measure of your journey.</p>
              </div>
              {/* <div className="text-center md:text-left md:ml-4 w-full md:w-[20%]">
                <h2 className="mb-1 text-2xl font-medium">0.00</h2>
                <p className="text-[#646464] text-base font-normal font-lato">Booking Points</p>
              </div> */}
            </div>

          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} >

        <div className=' h-[90%] flex justify-center flex-col'>
          <h2 className="lg:text-[36px] xs:text-[29px] font-medium leading-[41.98px] text-center text-black font-avenir">
            Invite Your Friend
          </h2>
          <p className="text-color-lightblack text-center lg:text-[16px] xs:text-[15px] font-lato font-normal text-sm md:text-lg leading-6">
            Invite with your friends and earn reward points
          </p>

          <div className='flex items-center justify-center h-20 md:h-32 gap-5 border-red-500'>
            <div className='lg:w-[64px] md:w-[50px] lg:h-[64px] md:h-[50px] xs:w-[30px] xs:h-[30px]   border-red-600 rounded-full bg-color-lightblue flex items-center justify-center'>
              <TiSocialTwitter className='w-[30px] h-[30px] cursor-pointer  ' style={{ color: '#1da1f2' }} onClick={() => handleSendReferrel('twitter')} />
            </div>

            {/* <div className='lg:w-[64px] md:w-[50px] lg:h-[64px] md:h-[50px] xs:w-[30px] xs:h-[30px] border-red-600 rounded-full bg-color-lightblue flex items-center justify-center'>
              <FaFacebookF className='md:w-[30px] md:h-[30px] cursor-pointer' style={{ color: '#1877F2' }} onClick={()=>handleSendReferrel('facebook')} />
            </div> */}
            <div className='lg:w-[64px] md:w-[50px] lg:h-[64px] md:h-[50px] xs:w-[30px] xs:h-[30px] border-red-600 rounded-full bg-color-lightred flex items-center justify-center'>
              <FaRedditAlien className='md:w-[30px] md:h-[30px] cursor-pointer' style={{ color: '#FF4500' }} onClick={() => handleSendReferrel('reddit')} />
            </div>
            <div className='lg:w-[64px] md:w-[50px] lg:h-[64px] md:h-[50px] xs:w-[30px] xs:h-[30px] border-red-600 rounded-full bg-color-lightgreen flex items-center justify-center'>
              <FaWhatsapp className='md:w-[30px] md:h-[30px] cursor-pointer' style={{ color: '#25D366' }} onClick={() => handleSendReferrel('whatsapp')} />
            </div>
            <div className='lg:w-[64px] md:w-[50px] lg:h-[64px] md:h-[50px] xs:w-[30px] xs:h-[30px] border-red-600 rounded-full bg-color-lightyallow flex items-center justify-center'>
              <MdEmail className='md:w-[30px] md:h-[30px] cursor-pointer' style={{ color: '#E8AD21' }} onClick={() => handleSendReferrel('email')} />
            </div>
          </div>
          {/* <div className='relative flex justify-center items-center'>
            <input
              className='lg:w-[80%] md:w-[65%] w-[95%] h-[50px] px-1 md:px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 md:pl-4'
              type='text'
              placeholder='https://bnbyond.com/antoniotony'
              readOnly
            />
            <div className='absolute inset-y-0 right-0 flex items-center lg:pr-16 md:pr-24 pr-3 pointer-events-none'>
              <LuCopy />
            </div>
          </div> */}
        </div>
      </Modal>
    </>
  )
}

export default Referrals


