import React, { useEffect, useState } from 'react'
import Rating from 'react-rating';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const CancelledCard = ({ Cancelled }) => {

  //formating checkin date
  const handleCheckIn = (value) => {
    const checkInDate = new Date(value);
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', options);
    return formattedCheckInDate;
  }

  //calculating average rating and reviews
  const calculateAverageRating = (reviews) => {
    if (reviews && reviews.length > 0) {
      const total = reviews.reduce((acc, review) => acc + review.rating, 0);
      return Number((total / reviews.length).toFixed(1));
    }
    return 0;
  };

  return (
    <>
      <div className="md:flex flex-wrap gap-2">

        {Cancelled.map((items, index) => {
          console.log("items@@@", Cancelled)

          const averageRating = calculateAverageRating(items.property_id?.reviews || []);

          return (
            <>
              <div key={items._id || index} // Use a unique identifier
                className="md:w-[49%] my-2 border border-color-grey rounded-md overflow-hidden">
                <div className="md:flex gap-2">
                  <div className="w-full flex justify-between p-2 ">
                    <div>
                      {averageRating > 0 ?
                        <div className="flex items-center ">
                          <Rating
                            initialRating={averageRating}
                            readonly
                            emptySymbol={<FaRegStar color="#d3d3d3" />}
                            halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                            fullSymbol={<FaStar color="#ffd700" />}
                            fractions={2}
                          />
                          <span>({averageRating})</span>
                        </div>
                        :
                        <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                      }

                      <p className="text-xl font-semibold mt-2 min-h-16">
                        {items.property_id?.title?.length > 45
                          ? items.property_id?.title?.slice(0, 45) + "..."
                          : items.property_id?.title}
                      </p>

                      <div className='text-gray-600 lg:w-52 py-2'>
                        <span className="text-base font-normal">
                          {items.property_id.spaceTypeDetail &&
                            items.property_id.spaceTypeDetail.bathrooms &&
                            items.property_id.spaceTypeDetail.bedrooms &&
                            items.property_id.spaceTypeDetail.guests &&
                            items.property_id.spaceTypeDetail.kitchen && (
                              <>
                                Guests:{" "}
                                {items.property_id.spaceTypeDetail.guests &&
                                  items.property_id.spaceTypeDetail.guests}{" "}
                                Bedrooms:{" "}
                                {items.property_id.spaceTypeDetail.bedrooms &&
                                  items.property_id.spaceTypeDetail.bedrooms}{" "}
                                Kitchens:{" "}
                                {items.property_id.spaceTypeDetail.kitchen &&
                                  items.property_id.spaceTypeDetail.kitchen}{" "}
                                Bathrooms:{" "}
                                {items.property_id.spaceTypeDetail.bathrooms &&
                                  items.property_id.spaceTypeDetail.bathrooms}{" "}


                              </>
                            )}
                        </span>
                      </div>

                      <p className="text-sm text-gray-600">
                        {handleCheckIn(items.offerState.checkIn)}
                      </p>

                      <p className="text-sm text-gray-600 pt-4">
                        Booking Cancelled
                      </p>


                      <div className=' my-1 flex justify-between items-center'>
                        {/* <p className='text-sm'>From</p> */}
                      </div>
                    </div>

                  </div>

                  <div className="w-full md:w-96 lg:w-80 min-h-fit relative">
                    <img
                      src={require("assets/img/background.png")}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                    <span className="bg-color-red px-4 py-2 absolute top-2 text-sm text-white rounded-full right-2">
                      Cancelled
                    </span>
                  </div>
                </div>
              </div>
            </>
          );
        })
        }
      </div>

    </>
  )
}

export default CancelledCard